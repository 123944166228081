.icon-container {
  border-radius: 9999px;
  background-color: var(--redo-colors-background-bg-brand-secondary);
  width: 40px;
  height: 40px;
  padding: 10px;
}

.conversation-container {
  border: 1px solid var(--redo-colors-border-border-disabled);
  flex-grow: 1;
  overflow: scroll;
}

.merge-modal-conversation-container {
  border: 1px solid var(--redo-colors-border-border-disabled);
  flex-grow: 1;
  max-height: 300px;
  overflow: scroll;
  scrollbar-width: none;
}

.pill-container {
  display: flex !important;
  flex-direction: row;
  gap: 4px;
}

.active-conversation-container {
  composes: conversation-container;
  border: 2px solid var(--redo-colors-border-border-brand-solid);
  scrollbar-width: none;
}

.conversation-preview {
  overflow: scroll;
  overflow-wrap: break-word;
  width: 100%;
  scrollbar-width: none;
}

.button {
  flex: 1;
  border-width: 1px;
  border-style: solid;
}
