.label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
}

.hideEditor {
  display: none;
}

.info-icon {
  max-height: 16px;
  color: var(--redo-colors-foreground-fg-disabled);
  svg {
    height: 16px;
    width: 16px;
  }
}

.readonly-text {
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-corner-small-radius);
  padding: var(--redo-spacing);
}
