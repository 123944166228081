@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.options {
  display: flex;
  &.seamless {
    padding-top: 32px;
    padding-inline: 48px;
  }
}

.date-range-container {
  margin-right: 16px;
}

.cell {
  font-size: var(--redo-body-small-text-size);
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
  &.seamless {
    padding: 12px 24px;
  }
  &.compact {
    padding: 8px;
  }
  &.wide {
    padding: 20px 20px 20px 8px;
  }
}

.cell-loading {
  border-radius: 4px;
  height: 12px;
  margin: 8px 0;
  background: linear-gradient(
    to right,
    var(--redo-colors-background-bg-quaternary) 20%,
    var(--redo-colors-background-bg-tertiary) 50%,
    var(--redo-colors-background-bg-quaternary) 80%
  );
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  color: var(--redo-colors-text-text-tertiary-600);
}

.main {
  background: var(--redo-colors-background-bg-primary);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-collapse: collapse;
  border-radius: var(--redo-corner-small-radius);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--redo-spacing);
  min-height: 0;
  width: 100%;
  &.compact {
    padding: 24px;
  }
}

.data {
}

.data-row {
  transition: background-color 250ms;
}

.data-row td:first-child {
  &.wide {
    padding-left: 28px;
    font-weight: 500;
  }
}

.data-row:hover {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.data-row.clickable {
  cursor: pointer;
}

.title {
  font-size: var(--redo-title-large-text-size);
  font-weight: 600;
}

.empty {
  font-style: italic;
  padding: 20px 4px;
}

.filters {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  justify-content: space-between;
}

.filter {
  cursor: pointer;
  border-radius: var(--redo-corner-small-radius);
  min-width: 130px;
  padding: 6px 8px;
  transition: 250ms background-color;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: baseline;
}

.filter-label {
  color: var(--redo-colors-text-text-tertiary-600);
  font-size: var(--redo-body-medium-text-size);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-count {
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-small-text-size);
  margin-left: 4px;
  opacity: 0.75;
}

.filter:not(.active):hover .filter-label,
.filter:not(.active):hover .filter-count {
  color: var(--redo-primary-color);
}

.filter.active {
  background: var(--redo-primary-color);
}

.filter.active .filter-label,
.filter.active .filter-count {
  color: white;
}

.textHeader {
  padding: 24px 0px 8px 24px;
}

.header {
  top: calc(var(--sticky-start) + var(--redo-spacing));
  background-color: var(--redo-colors-background-bg-secondary);
  z-index: 5;
  &.seamless {
    background: var(--redo-colors-background-bg-tertiary);
  }
  &.compact {
    padding: 4px;
  }
  &.wide {
    border-bottom: 1px solid var(--redo-colors-border-border-secondary);
    border-top: 1px solid var(--redo-colors-border-border-secondary);
    padding: 5px 40px 5px 0px;
    font-weight: 400;
  }
}

.header:first-child {
  &.compact {
    border-top-left-radius: var(--redo-corner-small-radius);
  }
  &.wide {
    padding-left: 20px;
  }
  /* border-bottom-left-radius: var(--redo-corner-small-radius); */
}

.header:last-child {
  &.compact {
    border-top-right-radius: var(--redo-corner-small-radius);
  }
  /* border-bottom-right-radius: var(--redo-corner-small-radius); */
}

.header-button {
  cursor: pointer;
  border-radius: var(--redo-corner-small-radius);
  transition: color 250ms;
}

.header-button:hover {
  color: #745fe2;
}

.header-content {
  font-size: var(--redo-body-small-text-size);
  padding: 8px;
  &.seamless {
    padding: 12px 24px;
  }
  &.compact {
    font-weight: bold;
  }
}

.header:not(.active) .header-button:not(:hover) .header-sort {
  visibility: hidden;
}

.header-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-sort {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
  margin-left: 4px;
  transition: transform var(--redo-collpase-duration);
}

.header-sort-container {
  display: inline-block;
  width: 0;
  vertical-align: middle;
}

.header-sort-asc {
}

.header-sort-desc {
  transform: rotate(-180deg);
}

.header-spacer {
  background: var(--redo-colors-background-bg-primary);
  height: var(--redo-spacing);
  top: var(--sticky-start);
  position: sticky;
}

.header-row {
  position: relative;
  &.seamless {
    padding: 8px;
  }
}

.download-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
}

.headers {
}

.loader-container {
  position: absolute;
  height: 400px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  opacity: 0.9;
  width: 200px;
  height: 200px;
}

.left {
  text-align: left;
  overflow: auto;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.spacer {
  margin: auto;
}

.table-container {
  flex: 1;
  margin-top: calc(var(--redo-spacing) * -1);
}

.table {
  width: 100%;
  margin-bottom: auto;
  table-layout: fixed;
  position: relative;
}

.table-fit-to-parent {
  width: auto;
  min-width: 100% !important;
}

.filter-container {
  display: flex;
  align-items: center;
  > * {
    margin-right: 16px;
  }
}

.page-control {
  align-items: center;
  padding: 8px 16px 16px 16px;
}

.page-control-sticky {
  position: sticky;
  bottom: 0;
  background: var(--redo-colors-background-bg-primary);
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  background-color: var(--redo-colors-background-bg-secondary);
}

.page-numbers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.page-change-button {
  font-size: var(--redo-body-small-text-size);
}
