.primary-nav {
  width: 64px;
  max-width: 64px;
}

.secondary-nav {
  width: 176px;
  max-width: 176px;
  overflow-y: auto;
  scrollbar-width: none;
}

.settings-nav {
  width: 240px;
  max-width: 240px;
}

.settings-nav-items {
  overflow-y: auto;
  scrollbar-width: none;
}

.nav-item,
.nav-profile,
.back-button {
  transition: background-color 0.2s ease-in-out;
  &.active {
    background-color: var(--redo-colors-background-bg-active);
  }
  &:hover {
    background-color: var(--redo-colors-background-bg-quaternary);
  }
}

.team-item,
.user-profile-item {
  border-radius: 50%;
  transition: outline-width;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  outline-width: 0px;
  outline-style: solid;
  outline-color: var(--redo-colors-background-bg-quaternary);
  &:hover {
    outline-width: 6px;
  }
}

.drag-handle {
  opacity: 0;
  &.show {
    opacity: 1;
  }

  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 20px;
  height: 28px;
}

.nav-item-icon {
  width: 16px;
  height: 16px;
  color: var(--redo-colors-foreground-fg-quinary-400);
  &.active {
    color: var(--redo-colors-text-text-white);
  }
}

.dropdown-icon {
  transform: rotate(-90deg);
  &.active {
    transform: rotate(0);
  }
}

.options-container {
  overflow: hidden;
  transition:
    height var(--redo-collapse-duration),
    padding-top var(--redo-collapse-duration);
}

.collapse-bar {
  width: 24px;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.side-nav {
  &.collapse-mode {
    position: absolute;
    z-index: 1301;
    height: 100%;
    left: 0px;
    bottom: 0px;
  }

  min-width: 1px;
  overflow: hidden;

  transition: width var(--redo-collapse-duration);
}

.primary-tab {
  position: relative;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
  height: 32px;

  transition: background-color 0.2s ease-in-out;

  border-radius: 4px;

  &.selected {
    background-color: var(--redo-colors-background-bg-secondary);
  }

  &:hover {
    background-color: var(--redo-colors-background-bg-quaternary);
  }

  .icon-container {
    display: flex;
  }

  .notification-dot {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--redo-colors-foreground-fg-error-primary);
  }
}

.icon-swapper {
  position: relative;

  > svg {
    &.hidden {
      opacity: 0;
    }
    position: absolute;
    transition: opacity 200ms ease-in-out;
    opacity: 1;
  }
}
