.audio-player {
  width: 352px;
}

:global(.rhap_repeat-button) {
  display: none;
}

:global(.rhap_container) {
  background-color: var(--redo-colors-gray-dark-mode-950);
  border-radius: var(--redo-corner-small-radius);
}

:global(.rhap_time) {
  color: #fff;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
}

:global(.rhap_volume-button) {
  height: 29px;
  width: 29px;
}

:global(.rhap_controls-section) {
  gap: var(--redo-spacing);
}

:global(.rhap_main-controls-button) {
  height: 28px;
  width: 28px;
}

:global(.rhap_play-pause-button) {
  height: 28px;
  width: 28px;
}

:global(.rhap_volume-bar-area) {
  max-width: 48px;
}

:global(.rhap_total-time) {
  font-weight: 500;
  color: var(--redo-utility-gray);
}
