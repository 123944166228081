.address-fields {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  padding: 16px;
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.line-items {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow-y: auto;
  padding: 16px;
}

.order-name {
  font-size: 18px;
  font-weight: 600;
}

.strong {
  font-size: 14px;
  font-weight: 600;
}

.warning {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}
