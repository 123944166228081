.divider {
  flex: 1;
  border-bottom: var(--redo-border-width) solid black;
}

.system-message {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  min-width: none;
  max-width: 60%;
  text-align: center;
}

.system-message-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
