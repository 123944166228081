.header-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  text-justify: center;
  line-height: 1;
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
}

.icon,
.icon > svg {
  width: 14px;
  height: 14px;
  max-width: 14px;
  max-height: 14px;

  &.help {
    color: var(--redo-colors-foreground-fg-quinary-400);
  }
  &.arrow {
    color: var(--redo-colors-foreground-fg-tertiary-600);
    font-weight: 400;
  }
}

.arrowButton {
  display: contents;
}

.adjustable-width-border {
  position: relative;
  width: 16px;
  height: 100%;
  cursor: col-resize;
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
}

.adjustable-width-border:hover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7px;
  width: 2px;
  background-color: var(--redo-colors-border-border-primary);
}

.adjustable-width-border-held {
  cursor: col-resize;
}

.non-adjustable-width-border {
  width: 2px;
  margin-right: 7px;
  height: 100%;
}

.non-adjustable-width-border-held {
  background-color: var(--redo-colors-border-border-primary);
  cursor: col-resize;
}
