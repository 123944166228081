.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 45%;
}

.details-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow: auto;
  padding-right: 8px;
}

.message {
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal-content {
  overflow: auto;
  padding-right: 8px;
}

.modal-header {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
}

.modal-footer {
  justify-content: end;
  gap: var(--redo-spacing);
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.name {
  font-weight: 600;
}

.option-container {
  display: flex;
  justify-content: space-between;
  width: 550px;
  align-items: center;
}

.option-title {
  font-weight: 500;
  font-size: 16px;
}

.pill-container {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  overflow-x: auto;
  scrollbar-width: none;
  width: 32%;
  &:hover {
    scrollbar-width: thin;
  }
}

.selected-conversations-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selected-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
