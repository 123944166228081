.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  padding: 16px;
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.gray {
  color: var(--redo-light-gray);
}

.line-item {
  display: flex;
  justify-content: space-between;
}

.line-item-image {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  border-radius: var(--redo-corner-small-radius);
  object-fit: cover;
}

.line-item-info {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.line-item-text {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  justify-content: center;
}

.line-items {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow-y: auto;
  padding: 16px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}

.order-name {
  font-size: 18px;
  font-weight: 600;
}

.refund-amount {
  max-width: 50%;
}
