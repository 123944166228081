.panel-container {
  width: 100%;
  max-width: 280px;
  background-color: var(--redo-colors-background-bg-primary);
}

.header-container {
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.content-container {
  overflow-y: auto;

  .empty-state-text {
    text-align: center;
  }
}

.customer-activity-filter-drawer {
  display: flex;
  flex-direction: column;

  width: 408px;

  .drawer-close-button-container {
    position: absolute;
    top: 12px;
    right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
  }
}
