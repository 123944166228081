.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.attachment-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    width: 90%;
  }
}

.file-input {
  display: none;
}

.footer {
  padding: 16px;
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
  width: 100%;
}

.file-upload-wrapper {
  display: flex;
  align-items: center;
  background-position: center;
  cursor: pointer;
  position: relative;
  border-radius: var(--border-radius-override, 10px);
  max-width: 100%;
  outline: var(--redo-active-border-width) solid transparent;
  font-size: var(--redo-body-medium-text-size);
  padding: 12px 20px;
  border: solid var(--redo-border-width) transparent;
  background: transparent;
  color: #000;
  height: 100%;

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #aaa !important;
  }
}

.macro-button {
  margin-right: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow-y: auto;
  padding: 16px;
}

.paperclip {
  height: 21px;
}

.quill-editor {
  border: 1px solid var(--redo-colors-border-border-secondary) !important;
  border-radius: 10px !important;
  padding-bottom: 48px !important;
}
