.buttons {
  display: flex;
  gap: var(--redo-spacing);
}

.cancel-confirm {
  display: flex;
}

.close-button {
  margin-left: auto;

  & svg circle {
    fill: (
      --redo-component-colors-components-buttons-secondary-button-secondary-bg
    );
  }

  & svg g * {
    stroke: var(--redo-colors-text-text-secondary-700);
  }
}

.column {
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 700px;
  & > * {
    flex: 1;
  }
}

.container {
  width: 100%;
}

.details-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 3);
  padding: 24px;
}

.detail-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(--redo-colors-text-text-secondary-700);
}

.detail-title {
  display: flex;
  gap: var(--redo-spacing);
  flex: 1;
  width: 100%;

  & > label {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
}

.header-with-close {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.icon {
  --size: 20px;
  height: var(--size);
  width: var(--size);
}

.information-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.info-icon {
  min-width: 20px;
  min-height: 20px;
}

.side-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  overflow: auto;
  background-color: var(--redo-colors-background-bg-primary);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.25);
  transition: width var(--redo-state-duration) ease-in-out;
  z-index: 1000;
}

.side-drawer-open {
  width: 400px;
}

.sticky-footer {
  position: fixed;
  bottom: 16px;
  left: 50%;
  z-index: 1100;
  display: flex;
  gap: var(--redo-spacing);
}

.type-option {
  display: flex;
}

.buttons-active {
  margin-right: 400px;
}
