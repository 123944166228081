.calendar-view {
  > * {
    border-width: 0px;
    border-color: var(--redo-colors-border-border-primary);
    border-style: solid;
    &:not(:last-child) {
      border-right-width: 1px;
    }
  }
}
