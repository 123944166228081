.value {
  display: flex;
  align-items: baseline;
  gap: var(--redo-spacing);
  overflow: hidden;
}

.title {
  white-space: nowrap;
}

.subtitle {
  flex: 1;
  font-size: var(--redo-body-small-text-size);
  color: #555;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  height: 18px;
  width: 18px;
  color: #555;
  align-self: center;
}
