.card {
  gap: 18px;
  padding: 16px;
}

.trackingNumberLink {
  color: var(--redo-colors-orange-dark-500) !important;
}

.trackingNumberLink:hover {
  color: var(--redo-colors-orange-dark-500) !important;
  text-decoration: underline;
}

.titleColor {
  color: var(--redo-color-text-tertiary);
}
