.label-container {
  row-gap: 10px !important; /* Needs to take precedence over regular arbiter Flex row-gap. */
  min-width: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right-info {
  gap: 0px !important; /* Needs to take precedence over regular arbiter Flex gap. */
  overflow: hidden;
}

.info-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
