.base {
  width: 100%;
}

.update-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: underline;
    font-weight: 600;
  }
}
