.bubble {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-stack-container {
  display: flex;
  flex-direction: row;
  > :last-child {
    width: fit-content;
  }
}

.avatar-container {
  width: 18.5px;
  overflow: visible;
  position: relative;
  img {
    max-width: none;
  }
}

.avatar {
  border-radius: 50%;
  width: fit-content;
  border: 1.5px solid var(--redo-colors-background-bg-primary);
  /* This background shouldn't ever be visible, but it is.
  * There's a small crack between the border and the padding.
  * Without a background, whatever is behind this avatar (probably the previous avatar) shows through. */
  background: white;
}

.avatar-spacer-container {
  width: 1.5px;
  overflow: visible;
  position: relative;
}

.avatar-spacer {
  width: 24px;
  height: 24px;
}

.remainder-bubble {
  border: 0.5px solid #00000014;
  background: var(--redo-colors-background-bg-secondary);
  color: var(--redo-colors-text-text-tertiary-600);
  font-weight: 600;
}
