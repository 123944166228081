.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.paperclip {
  height: 21px;
}

.file-input {
  display: none;
}
