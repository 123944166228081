.banner {
  border-left: 1px solid var(--redo-colors-border-border-primary-active);
  height: 52px;
  width: 100%;
  background-color: var(--redo-colors-background-bg-alternate);
  padding: 0px calc(var(--redo-spacing) * 2);
}

.button-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
