.footer {
  height: 36px;
}

.image-preview-container {
  padding: var(--redo-spacing-lg) var(--redo-spacing-lg) 0px
    var(--redo-spacing-lg);
}

.image-preview-item {
  position: relative;
  width: 48px;
  height: 48px;
}

.image-preview-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-spacing-xs);
}

.image-preview-delete-button {
  position: absolute;
  top: -7px;
  right: -4px;
  background: var(--redo-colors-background-bg-tertiary);
  border: 1px solid var(--redo-colors-border-border-primary);
  box-shadow: 0px 1px 2px 0px var(--redo-shadow-color-primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.image-preview-delete-button svg {
  width: 12px;
  height: 12px;
  color: var(--redo-colors-text-text-primary);
}

.message-input-form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: var(--redo-spacing-md);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.quill-editor {
  border: none !important;
  margin-bottom: 24px;
  margin-top: 16px;
  padding: 0 var(--redo-spacing-xl) !important;
  font-family: var(--redo-font-family-body) !important;
}

.quill-editor :global(.ql-editor) {
  padding: 0 !important;
  font-family: var(--redo-font-family-body) !important;
}

.quill-editor :global(.ql-editor.ql-blank::before) {
  font-style: normal !important;
  font-family: var(--redo-font-family-body) !important;
  color: var(--redo-colors-text-text-placeholder);
  padding: 0 !important;
}

.footer-button svg {
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
}

.footer-button:not(:last-child) {
  padding-right: var(--redo-spacing-xs);
  position: relative;
}

.footer-button:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 24px;
  background-color: var(--redo-colors-border-border-primary);
}

.footer-button:not(:first-child) {
  padding-left: var(--redo-spacing-xs);
}
