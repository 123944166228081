.star {
  &:not(.readonly) {
    cursor: pointer;
  }
  stop:first-of-type {
    stop-color: currentColor;
  }
  stop:last-of-type {
    stop-color: var(--redo-colors-background-bg-quaternary);
  }
}
