.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 28px;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.action-buttons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.back-wrapper {
  display: flex;
  flex-direction: row;
}

.cell-container {
  padding: 16px;
}

.checkbox-container {
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  min-width: 0px;
}

.error-icon {
  display: flex;
  justify-content: center;
  color: var(--redo-colors-foreground-fg-error-secondary);
  > svg {
    height: 16px;
    width: 16px;
  }
}

.content-header {
  z-index: 1;
  background-color: var(--redo-colors-background-bg-secondary);
  padding-left: var(--redo-web-page-content-padding-left);
  padding-right: var(--redo-web-page-content-padding-right);

  padding-top: 16px;
  z-index: 2;
  padding-bottom: var(--redo-spacing);
  &:not(.scrolled-top) {
    box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.15);
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dropdown-items-container {
  .dropdown-item {
    display: flex;
    flex-direction: row;
    svg {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.dropdown-title {
  font-weight: bold;
  padding: 8px;
}

.filter {
  width: 16px;
  height: 16px;
}

.header-action-buttons {
  flex: 1;
}

.header-checkbox-container {
  min-width: 16px;
  display: flex;
  justify-content: center;
  margin-left: calc(var(--redo-spacing) * 2);
}

.portal-buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);

  &.align-center {
    align-items: center;
  }
}

.gray {
  color: var(--redo-colors-text-text-disabled);
}

.icon {
  height: 16px;
  width: 16px;
}

.menu-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.draft-reply-badge-container {
  display: inline-flex;
  margin-right: 4px;
}

.options-button {
  display: flex;
  align-items: center;
}

.plus {
  width: 21px;
  height: 21px;
}

.red {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}

.search {
  display: flex;
  align-items: center;
  gap: calc(var(--redo-spacing) * 2);
}

.selected-conversations-count {
  margin: auto;
}

.conversations-table-wrapper {
  background-color: var(--redo-colors-background-bg-secondary);
  table {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}

.spinner {
  width: 100px;

  & > div {
    width: 40px;
  }
}

.strong {
  font-weight: bold;
}

.summary-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
}

.tag-container {
  display: flex;
  overflow-x: auto;
  gap: var(--redo-spacing);
  scrollbar-width: none;
}

.three-dots {
  button,
  div {
    height: 100%;
  }
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.unread {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--redo-primary-color);
  margin: auto;
}

.table-container {
  overflow-x: auto;
  display: flex;
  flex: 1 1 0%;
}

.noMoreTicketsIcon {
  font-size: 40px;
}

.noMoreTicketsSubText {
  max-width: 272px;
  overflow: wrap;
  text-align: center;
}
