.action-buttons-container {
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.line-item-image {
  width: 72px;
  height: 72px;
  min-height: 72px;
  min-width: 72px;
  border-radius: 10px;
  object-fit: cover;
}

.footer {
  padding: 16px;
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  flex: 1;
}

.info-section-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
}

.info-section-title-row {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.item-details {
  margin: 12px 0px;
}

.line {
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.line-items {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow-y: auto;
  padding: 16px;
}

.multi-line-info {
  display: flex;
  flex-direction: column;
}

.order-name {
  font-size: 18px;
  font-weight: 600;
}

.strong {
  font-size: 14px;
  font-weight: 600;
}
