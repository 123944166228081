.virtual-table-container {
  overflow: auto;
  position: relative;
  background-color: var(--redo-colors-background-bg-primary);
}

.table {
  display: grid;
  min-width: 0px;
  min-height: 0px;
  background-color: var(--redo-colors-background-bg-primary);
  width: 100%;
  height: 100%;
}

.header-cell {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: var(--redo-colors-background-bg-secondary);
  &.white {
    background-color: var(--redo-colors-background-bg-primary);
  }
}

.row-wrapper {
  display: contents;
}

.virtual-row-wrapper {
  position: absolute;
  width: 100%;
  display: grid;
}
