.wrapper {
  padding-left: var(--spacing);
  width: 135px;

  &.ghost {
    opacity: 0.5;
  }

  &.clone {
    padding-left: 0;
  }
}

.collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}

.hidden {
  opacity: 0;
}

.display-none {
  display: none;
}

.expandable-item {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
}

.expanded {
  max-height: 200px;
}

.folder-item {
  height: 28px;
}
