.text {
  text-align: center;
  font-weight: bold;
}

.button {
  width: 90%;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 112px;
}

.spinner-container {
  width: 64px;
}

.featured-link {
  text-align: center;
}
