.down-arrow {
  transform: rotate(-90deg);
}

.label {
  display: flex;
  justify-content: space-between;
}

.up-arrow {
  transform: rotate(90deg);
}

.info-tooltip-icon {
  color: var(--redo-colors-foreground-fg-quinary-400);
  width: 16px !important;
  height: 16px !important;
}
