.draft-attachment-container {
  position: relative;
  width: 48%;

  .remove-file-button {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: var(--redo-surface-danger-light-color);
    color: var(--redo-colors-base-black);
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.file-carousel {
  display: flex;
  flex-direction: row;
  gap: calc(var(--redo-spacing) * 2);
  overflow-x: auto;
  padding: 12px 12px 16px 16px;
  flex-wrap: wrap;
  min-height: fit-content;

  div:hover {
    .remove-file-button {
      display: flex;
    }
  }
}
