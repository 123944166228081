.action-buttons {
  display: flex;
  gap: var(--redo-spacing);
  margin-left: auto;
}

.product {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.results-container {
  overflow: scroll;
}

.product-container {
  margin: 8px;
  align-items: center;
}

.variant-container {
  margin: 8px 8px 8px 32px;
  align-items: center;
}

.product-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.product-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.search-input {
  margin: 4px;
}
