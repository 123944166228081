.above-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-button-container {
  display: flex;
  gap: calc(var(--redo-spacing) * 3);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ai-info-container {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  color: var(--redo-fg-brand-primary-color);
}

.bold {
  font-weight: bold;
}

.vertical-button-divider {
  border-right: 1px solid var(--redo-colors-border-border-secondary);
  height: 24px;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.action-icon {
  color: var(--redo-primary-button-color);
}

.attachment-wrapper {
  padding-left: 16px;
}

.check-icon {
  color: var(--redo-component-colors-utility-brand-utility-brand-400);
}

.editor-error-tooltip-wrapper {
  overflow: hidden;
}

.comment-thread-switch {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;

  > p {
    font-size: var(--redo-body-small-text-size);
  }
}

.draft-image {
  height: 75px;
  width: auto;
  cursor: pointer;
  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.draft-image-container {
  position: relative;
  flex-shrink: 0;

  &:hover {
    .remove-image-button {
      display: flex;
    }
  }
}

.draft-images {
  display: flex;
  flex-direction: row;
  gap: calc(var(--redo-spacing) * 2);
  overflow-x: auto;
  padding: 12px 12px 16px 0;
}

.editor {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  position: relative;
}

.edit-button {
  padding: 0px !important;
  width: 16px;
  height: 16px;
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.header-divider {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
}

.icon {
  height: 20px;
  width: 20px;
  color: var(--redo-colors-text-text-tertiary-600);
}

.info {
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-small-text-size);
}

.internal-divider > hr {
  border-color: #d9d9d9;
}

.internal-message p {
  color: #452ebb;
}

.message-input-card {
  padding: calc(var(--redo-spacing) * 2);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  max-height: 50%;
  overflow: auto;
  width: 100%;
  min-height: 274px;
  &.internal {
    background-color: var(--redo-colors-background-bg-primary);
    border-color: var(--redo-primary-color);
  }
}

.message-input-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 10px;
}

.message-input-form-internal {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  background-color: var(--redo-warning-color);
  border: 1px solid var(--redo-warning-color-dark);
  border-radius: 10px;
}

.options {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.public-message-merchant {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
}

.public-message-customer {
  display: flex;
  flex-direction: row;
  background-color: var(--redo-extra-light-gray);
}

.remove-image-button {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: var(--redo-surface-danger-light-color);
  color: var(--redo-colors-base-black);
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.reply-buttons {
  display: flex;
  gap: var(--redo-spacing);
  margin-left: auto;
}

.show-signature-button {
  position: absolute;
  left: 12px;
  bottom: 0px;
  height: 20px;
  display: flex;
  align-items: center;

  > div {
    height: 20px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.spinner {
  width: 20px;
  height: 20px;
}

.visibility-selector {
  display: flex;
}

.warning {
  color: var(--redo-component-colors-utility-error-utility-error-400);
  font-size: var(--redo-body-small-text-size);
}

.editor-container {
  background-color: var(--redo-colors-background-bg-primary);
}

.wrapping-text {
  white-space: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  flex: 1 1 0%;
  text-wrap: nowrap;
  flex-wrap: nowrap;
}

.writing-internal-note-top-border {
  border-top: 1px solid var(--redo-colors-border-border-primary);
}

.clicking-recipient-bar {
  cursor: pointer;
}
