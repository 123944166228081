.header-bar {
  border-bottom: 1px solid var(--redo-colors-border-border-primary);
  background-color: var(--redo-colors-background-bg-primary);
  height: 80px;
  &.collapsed {
    height: 44px;
  }
}

.subject-container {
  overflow: hidden;
  flex: 1 1 0%;
  min-width: 0px;
  text-overflow: ellipsis;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.left-header-content {
  flex: 1 1 0%;
  min-width: 0px;
}

.button-icon-wrapper {
  width: 16px;
  height: 16px;
}
