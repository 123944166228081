.item-details-grade-outcome-container {
  gap: 16px;
  flex-direction: row;
}

@media screen and (max-width: 400px) {
  .item-details-grade-outcome-container {
    gap: var(--redo-spacing-md);
    flex-direction: column;
  }
}

.item-details-labeled-input {
  display: flex;
  flex: 1;
}
