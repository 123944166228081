.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.light-action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
  color: var(--redo-colors-text-text-tertiary-600);
}

.bubble {
  display: flex;
  flex-direction: column;
  padding: var(--redo-spacing) calc(var(--redo-spacing) * 1.5);
  border-radius: 6px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  &.customer {
    background-color: var(--redo-surface-light-color);
    border: 1px solid var(--redo-colors-border-border-secondary);
    color: var(--redo-colors-text-text-primary-900);
    border-top-left-radius: 0px;
  }

  &.internal {
    background-color: var(--redo-warning-color);
    border-top-right-radius: 0px;
  }

  &.merchant {
    color: white !important;
    background-color: var(--redo-nav-color) !important;
    border-top-right-radius: 0px;
  }

  &.merchant span {
    color: white !important;
    background-color: var(--redo-nav-color) !important;
  }

  &.merchant strong {
    color: white !important;
    background-color: var(--redo-nav-color) !important;
  }

  &.customer a {
    color: var(--redo-primary-color) !important;
  }

  &.merchant a {
    color: var(--redo-primary-color) !important;
    background-color: var(--redo-nav-color) !important;
  }
}

.bubble-context {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: calc(var(--redo-spacing) * 0.5);

  &.customer {
    align-items: flex-start;
  }

  &.merchant {
    align-items: flex-end;
  }
}

.bubble-items {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);

  &.customer {
    align-items: flex-start;
  }

  &.merchant {
    align-items: flex-end;
  }
}

.divider {
  flex: 1;
  border-bottom: var(--redo-border-width) solid black;
}

.file-list {
  display: flex;
  flex-flow: row wrap;
  gap: calc(var(--redo-spacing) * 2);
  padding: 12px 12px 16px 0;
  height: auto;
}

.info-above {
  display: flex;
  flex-direction: column;
  gap: 0;

  > div {
    line-height: 1.3;
  }
}

.link {
  text-decoration: underline;
  color: var(--redo-primary-color);
  cursor: pointer;
  text-underline-position: under;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.message {
  display: flex;
  flex-direction: row;
  max-width: 70%;
  gap: var(--redo-spacing);

  &.customer {
    align-self: flex-start;
    justify-content: flex-start;
  }

  &.merchant {
    align-self: flex-end;
    justify-content: flex-end;
  }
}

.sender-name {
  color: var(--redo-colors-text-text-secondary-700);
  font-weight: 500;
  font-size: var(--redo-body-small-text-size);
}

.profile-picture {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.profile-picture img,
.profile-picture svg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.quill-toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sender-date {
  display: flex;
  gap: var(--redo-spacing);
  flex-shrink: 0;
  align-items: flex-end;
  align-self: flex-end;
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-small-text-size);
}

.sender-date-customer {
  display: flex;
  gap: var(--redo-spacing);
  flex-shrink: 0;
  align-items: flex-end;
  align-self: flex-start;
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-small-text-size);
}

.time {
  color: var(--redo-colors-text-text-disabled);
  flex-shrink: 0;
}

.uploaded-image {
  max-width: 160px;
  max-height: 160px;
  object-fit: cover;
  cursor: pointer;
}

.username {
  color: inherit;
}

.xsmall {
  font-size: var(--redo-body-xsmall-text-size);
}
