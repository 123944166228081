.title {
  color: var(--redo-colors-text-text-quarterary-500);
}

.info-item {
  margin-bottom: 12px;
}

.info-item:last-child {
  margin-bottom: 0px;
}
