.select {
  min-width: 28px;
  &:hover {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-100
    );
    border-radius: 8px;
  }
}

.button-container {
  min-width: 28px;
}

.format-select {
  width: 108px;
}

.font-size-select {
  min-width: 98px;
}

.formats {
  min-width: 136px;
}

.hidden {
  display: none;
}

.icon {
  width: 16px;
  height: 16px;
}
