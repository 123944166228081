.card {
  padding: var(--redo-spacing-lg);
}

.header-color {
  color: var(--redo-color-text-tertiary);
}

.content-color {
  color: var(--redo-color-text-primary);
}

.content-color-secondary {
  color: var(--redo-color-text-tertiary);
}
