.info-dropdown {
  & div > button {
    max-width: 20px;
    min-width: 0px;
    min-height: 0px;
    max-height: 20px;
    height: 20px;
    width: 20px;
  }
}

.subtle-button {
  padding: 0px !important; /* Needs to take precedence over regular arbiter button padding.*/
}

.subtle-button-icon-container {
  width: 20px !important; /* Needs to take precedence over regular arbiter button width. */
  height: 20px !important; /* Needs to take precedence over regular arbiter button height. */
  color: var(
    --redo-component-colors-components-buttons-tertiary-button-tertiary-fg
  );
  padding: 0px;
}

.shadow-dom-wrapper {
  all: initial;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  font-family: Inter;
  font-size: var(--redo-body-small-text-size);
  line-height: var(--redo-line-height);
  font-weight: 400;
  color: var(--redo-colors-text-text-primary-900);
}

.email-container {
  background-color: var(--redo-colors-background-bg-primary);
  border-bottom: 1px solid var(--redo-colors-border-border-primary);

  &.internal {
    background-color: var(
      --redo-component-colors-utility-warning-utility-warning-100
    );
    border-bottom: 1px solid
      var(--redo-component-colors-utility-warning-utility-warning-400);
  }
}

.email-header {
  &.normal {
    background-color: var(--redo-colors-background-bg-secondary);
  }
  &.internal {
    background-color: var(
      --redo-component-colors-utility-warning-utility-warning-200
    );
  }
  &.merchant {
    background-color: var(--redo-colors-background-bg-quaternary);
  }
  height: 72px;
}

.email-info-icon {
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  color: var(--redo-colors-foreground-fg-disabled);

  &.merchant {
    color: var(--redo-colors-foreground-fg-quarterary-500);
  }
}

.attachment {
  width: 48%;
}

.email-content-padded-container {
  padding-left: 58px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.toggle-view-replies-button {
  margin-left: 48px;
  width: 36px !important; /* Needs to take precedence over regular arbiter button width. */
  height: 36px !important;
}

.info-row-bounded-content {
  flex-basis: 100%;
  min-width: 0px;
  flex-shrink: 1;
}

.email-shortened-summary {
  overflow: hidden;
  text-overflow: ellipsis;
}

.reply-card {
  border-radius: 8px;
  border: 1px solid var(--redo-colors-border-border-primary);
  min-height: 100px;
}

.wrapping-text {
  white-space: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  flex: 1 1 0%;
  text-wrap: nowrap;
  flex-wrap: nowrap;
}

.message-input-container {
  min-width: 320px;
}
