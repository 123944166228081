.growable-input {
  max-width: 100%;
  min-height: fit-content !important; /** Competes with height of regular redo text input */
  height: auto;
}

.text-input {
  flex: 1;
  min-width: min(var(--input-width), 100%);
}

.tag-container {
  overflow: hidden;
  &:not(.disabled) {
    cursor: pointer;
    &:hover {
      filter: brightness(95%);
    }
    &:active {
      filter: brightness(90%);
    }
  }
}
