.modal-footer {
  justify-content: end;
  gap: 8px;
  display: flex;
  width: 100%;
}

.address-fields {
  display: flex;
  gap: 8px;
}

.grow {
  flex-grow: 1;
}
