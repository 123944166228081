.footer {
  padding: 16px;
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.inherit {
  color: inherit;
}

.input {
  align-self: flex-start;
  min-width: 200px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  padding: 16px;
  overflow-y: auto;
}

.quill-editor {
  border: 1px solid var(--redo-colors-border-border-secondary) !important;
  border-radius: 10px !important;
  min-height: 100px;
  font-size: var(--redo-body-medium-text-size);
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
}
