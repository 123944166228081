.disabled-tile {
  color: var(--redo-light-gray);
  background-color: transparent !important;
}

.date-time-button {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.header {
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
}

.line {
  border: 1px solid var(--redo-colors-border-border-secondary);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.snooze-option {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .time {
    color: #565755;
    margin-left: 24px;
  }
}

.date-time-buttons-container {
  margin-top: 16px;
  float: right;
}

.date-time-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  :first-child {
    margin-right: 4px;
  }
}

.date-time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.today-tile {
  border: 1px solid var(--redo-primary-color) !important;
}

.wrapper {
  padding: 0 var(--redo-spacing);
  overflow-y: auto;
}
