.button-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 20px;
  right: 20px;
  gap: var(--redo-spacing);
}

.button-container2 {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  padding: 16px;
  justify-content: end;
}

.button-section {
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.dropdown-items-container {
  .dropdown-item {
    display: flex;
    flex-direction: row;
    svg {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.header-button {
  color: #747474 !important;
  display: flex;
  align-items: center;
}

.icon {
  width: 22px;
  height: 22px;
}

.input {
  display: flex;
  flex-direction: column;
}

.macro-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
  overflow: auto;
}

.micro-text {
  font-size: 10px;
  color: #747474;
}

.options-button {
  display: flex;
  align-items: center;
}

.pill-content {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
}

.preview {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 20px 16px;
  gap: var(--redo-spacing);
}

.preview2 {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: calc(var(--redo-spacing) * 2);
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 0;
}

.selected-macro {
  cursor: pointer;
  background-color: var(--redo-colors-border-border-secondary);
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid var(--redo-colors-border-border-secondary);
  max-width: 300px;
}

.sidebar-list {
  padding: calc(var(--redo-spacing) * 2);
  overflow: auto;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: #747474;
}

.sidebar-list {
  overflow-y: auto;
}

.sidebar-list-item {
  margin: calc(var(--redo-spacing) * 2) 0;
  padding: 0 12px;
  cursor: pointer;
}

.sidebar-search {
  padding: 12px;
}

.status-pill-container {
  align-self: start;
}

.tags-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  flex-wrap: wrap;
}

.unselected-macro {
  cursor: pointer;
  &:hover {
    background-color: var(--redo-colors-border-border-secondary);
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.quill-editor {
  border: 1px solid var(--redo-colors-border-border-secondary) !important;
  border-radius: 10px !important;
  min-height: 100px;
  font-size: var(--redo-body-medium-text-size);
}

.file-input {
  display: none;
}

.paperclip {
  height: 21px;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.variable {
  background-color: #ebe9fd;
  color: #5f45e2;
  border-radius: var(--redo-corner-xsmall-radius);
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.snooze-duration-wrapper {
  padding: 0 var(--redo-spacing);
  overflow-y: auto;
}

.modal {
  height: 80%;
  max-height: 80%;
  min-width: min(1000px, 100%);
  max-width: 1200px;
  width: 90%;
}
