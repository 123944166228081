:global(.react-date-picker__wrapper) {
  border-radius: 10px;
  padding: 12px 20px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  outline: var(--redo-active-border-width) solid transparent;
  background-color: var(--redo-colors-background-bg-primary);

  &:hover {
    border-color: var(--redo-colors-border-border-secondary);
  }
}

:global(.react-date-picker__calendar) {
  inset: 120% auto auto 0px !important;
  width: auto;
}

:global(.react-calendar) {
  /* padding ensures the squares are the same size */
  padding: 24px 27px;
  border-radius: 10px;
  border: none;
  * {
    font-family: var(--redo-body-font-family);
  }
}

:global(.react-date-picker__button:enabled:hover) {
  * {
    stroke: var(--redo-primary-color);
  }
}

:global(.react-calendar__navigation) {
  text-align: center;
}

:global(.react-calendar__tile) {
  color: var(--redo-colors-base-black);
  background: none;
  line-height: 22px;
  position: relative;
  border-radius: 42px;

  &:disabled {
    border-radius: 0;
  }
}

:global(
  .react-calendar__tile:enabled:hover:not(.react-calendar__tile--active)
) {
  color: var(--redo-colors-base-black);
  background: #efecfd;
}

:global(.react-calendar__tile--hover) {
  color: var(--redo-colors-base-black);
  background: #efecfd;
}

:global(.react-calendar--selectRange) {
  :global(.react-calendar__tile--hover) {
    color: var(--redo-colors-base-black);
    background: #efecfd;
  }
}

:global(.react-calendar__navigation button:enabled:hover),
:global(.react-calendar__navigation button:enabled:focus) {
  background: #efecfd;
}

:global(.react-calendar__tile--active),
:global(.react-calendar__tile--hover) {
  /* color: white;
    background-color: var(--redo-primary-color); */
  color: var(--redo-colors-base-black);
  background: #efecfd;
  border-radius: 0;
}

:global(.react-calendar__tile--active:enabled:hover),
:global(.react-calendar__tile--active:enabled:focus) {
  /* color: white;
    background-color: var(--redo-primary-color); */
  color: var(--redo-colors-base-black);
  background: #efecfd;
  filter: brightness(0.9);
}

:global(.react-calendar__navigation__arrow) {
  font-size: 22px;
  border-radius: 44px;
}

:global(.react-calendar__navigation__label) {
  padding: 0px 8px;
  border-radius: 10px;
}

:global(.react-calendar__tile:disabled) {
  cursor: disabled;
}

:global(.react-date-picker__inputGroup__input:invalid) {
  background: transparent;
}

:global(.react-calendar__month-view__days) {
  /* Styles for ends of selected range */
  :global(
    .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverStart),
  :global(
    .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverEnd),
  :global(
    .react-calendar__tile--rangeBothEnds:not(.react-calendar__tile--hover)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds) {
    color: white !important;
    filter: unset !important;
    * {
      position: relative;
    }
    &::before {
      top: 0px;
      left: 0px;
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      border-radius: 42px;
      background-color: var(--redo-primary-color);
    }
  }

  /* Start of range */
  :global(
    .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverStart) {
    border-radius: 42px 0 0 42px;
  }

  /* End of range */
  :global(
    .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverEnd) {
    border-radius: 0 42px 42px 0;
  }

  /* Both ends */
  :global(
    .react-calendar__tile--rangeBothEnds:not(.react-calendar__tile--hover)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds) {
    border-radius: 42px;
  }
}
