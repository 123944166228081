.down-arrow {
  transform: rotate(-90deg);
}

.label {
  display: flex;
  gap: 8px;

  & > :first-child {
    margin-right: auto;
  }
}

.up-arrow {
  transform: rotate(90deg);
}
