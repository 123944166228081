.container {
  display: flex;
  flex-direction: row;
  padding: 12px 16px 12px 16px;
  user-select: none;

  &:hover {
    background-color: var(--redo-colors-background-bg-secondary_hover);
  }
  &.selected {
    background-color: var(--redo-colors-background-bg-secondary);
  }
  &:active {
    background-color: var(--redo-colors-background-bg-secondary_hover);
  }
  &.loading {
    background-color: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.customer-title {
  max-width: calc(100% - 8px); /* 8px is the width of the unread indicator */
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.empty {
  padding: 16px;
  text-align: center;
  margin: auto;
}

.gray {
  color: var(--redo-colors-text-text-disabled);
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  font-size: var(--redo-body-small-text-size);
}

.loading-card {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.pill-container {
  flex: 1;
  display: flex;
  gap: 4px;
  overflow-x: auto;
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.person-name {
  width: 100%;
}

.unread {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--redo-primary-color);
}

.unread-indicator {
  display: flex;
  align-items: center;
}
