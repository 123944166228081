.summary-title {
  font-weight: 600;
  margin-bottom: var(--redo-spacing);
  height: calc(var(--redo-spacing) * 2);
  line-height: calc(var(--redo-spacing) * 2);
}

.generateAiSummaryButton {
  border-radius: 6px;
  height: 28px;
}

.star-icon-box {
  width: 14.67px;
  height: 14.67px;
}
