.dropdownContainer {
  margin: calc(var(--redo-spacing-unit) * -1);
}

.listItemWrapper {
  width: 220px;
}

.listItem {
  width: 100%;
  height: 100%;
}

.operationText {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-align {
  text-align: start;
}
