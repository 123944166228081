.row {
  display: contents;
}

.left {
  justify-content: flex-start;
}
.center {
  justify-content: center;
}
.right {
  justify-content: flex-end;
}

.hoverable {
  /* Included to prevent css lint warning */
  --dummy-var: 1;
}

.row:hover:not(:has(.hoverable:hover)) > div,
.selected-row > div {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.clickable-row {
  cursor: pointer;
}
