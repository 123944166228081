.progress-bar {
  width: 100%;
  height: 16px;
  background-color: var(--redo-primary-extra-light-color);
  border-radius: var(--redo-corner-xsmall-radius);
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  background-color: var(--redo-primary-color);
  transition: width var(--redo-state-duration) ease;
}
