.card {
  display: flex;
  flex-direction: column;
  gap: 0px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  background: var(--redo-colors-background-bg-primary);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  min-width: min-content;
  width: 100%;
  overflow-x: auto;
  padding: 0px;
}

.summary-element {
  justify-content: space-between;
}

.container {
  gap: var(--redo-spacing-4xl);
}

.icon {
  height: 12px;
  width: 12px;
  align-items: center;
  align-self: center;
  margin-right: 4px;
  top: 1px;
  left: 1px;
}

.items {
  gap: var(--redo-spacing-xl);
  border-bottom: 1px;
  display: flex;
  flex-direction: column;
}

.item-comments {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-sm);
}

.item-comment-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: var(--redo-corner-xsmall-radius);
}

.item-comment-images {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing-lg);
}

.add-new-item-button {
  padding: 0px;
  color: #525252;
  width: fit-content;
  cursor: pointer;
  align-items: center;
}

.add-new-item-button-text {
  color: #525252;
}

.add-new-item-button-text:hover {
  color: #525252 !important;
  text-decoration: none !important;
}

.add-new-item-icon {
  color: #525252 !important;
  height: 16px;
  width: 16px;
}

.newItemShipping {
  gap: var(--redo-spacing-sm);
}

.shipping-text {
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-xsmall-text-size);
  line-height: 16px;
  color: var(--redo-color-text-secondary);
}

.shipping-text-header {
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-xsmall-text-size);
  line-height: 16px;
  color: var(--redo-color-text-tertiary);
}

.new-item-button-text {
  line-height: 16px;
  font-weight: 500;
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-color-text-tertiary);
}
.edit-icon {
  color: var(--redo-colors-border-border-primary-active);
  height: 14px;
  width: 14px;
  align-self: center;
  margin-bottom: -1px;
}

.clickable {
  cursor: pointer;
}

.error {
  margin-top: -10px;
  display: flex;
  width: 100%;
  justify-content: right;
  color: rgb(255, 81, 81);
}

.chevron {
  height: 18px;
  transform: rotate(-90deg);
  transition: transform 0.2s ease;
}

.new-address {
  /* display: flex; */
  /* align-items: center; */
  /* gap: var(--redo-spacing); */
  h4 {
    font-size: 14px;
    display: inline;
  }
  address {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  a {
    font-size: 12px;
  }
}

.item {
  display: flex;
  align-items: flex-start;
  gap: var(--redo-spacing-xl);
  padding: var(--redo-spacing-2xl) var(--redo-spacing-2xl)
    var(--redo-spacing-2xl) var(--redo-spacing-lg);
  border-radius: var(--redo-corner-medium-radius);
}

/* Only add border if it's not the last item */
.item:not(:last-child) {
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.item-image {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-height: 60px;
  object-fit: cover;
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: 4px;
  flex-shrink: 0;
}

.item-image img {
  object-fit: cover;
}

.item-main {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-xl);
  width: 100%;
}

.item-properties {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: var(--redo-body-xsmall-text-size);
  max-width: 350px;
  min-width: fit-content;
}

.item-property {
  display: flex;
  gap: 6px;
  align-items: center;
}

.item-property-name {
  font-weight: bold;
  justify-self: end;
  display: flex;
  align-items: center;
}

.item-header {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
}

.item-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  width: 100%;
  justify-content: space-between;
  height: 24px;
  min-width: 0;
}

.item-options {
  display: flex;
  gap: var(--redo-spacing);
  margin-top: -4px;
}

.item-options,
.item-options-text {
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-xsmall-text-size);
}

.item-title-icon {
  height: 1em;
  margin-top: -2px;
}

.item-option:not(:last-child) {
  padding-right: 8px;
  border-right: 1px solid var(--redo-colors-text-text-disabled);
}

.item-type {
  flex-basis: auto;
  font-weight: normal;
  position: relative;
}

.item-price-edit {
  display: flex;
  justify-content: right;
  gap: var(--redo-spacing);
  align-items: center;
  overflow: hidden;
}

.item-price {
  display: inline-flex;
}

.item-subtitle {
  display: flex;
  gap: var(--redo-spacing-xs);
}

.item-subtitle-text {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-colors-text-text-disabled);
  font-family: var(--redo-body-font-family);
  font-weight: 400;
  line-height: 18px;
  height: 18px;
}

.item-subtitle > *:not(:last-child)::after {
  content: "/";
  margin-left: var(--redo-spacing-xs);
}

.non-price-container {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  min-width: 0;
  flex: 1;
  white-space: nowrap;
  overflow: visible;
}

.edit-new-items-link {
  color: var(--redo-primary-color);
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.add-icon {
  padding-top: 4px;
}

.original-price {
  display: flex;
  width: 100%;
  position: relative;
  font-size: var(--redo-body-xsmall-text-size);
}

.right {
  justify-content: right;
}

.item-price-container {
  gap: var(--redo-spacing-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-price-text {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-primary-900);
  font-weight: 500;
  font-family: var(--redo-body-font-family);
}

.item-summary {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: fit-content;
}

.item-summary-line {
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.item-summary-value {
  font-size: 13px;
}

.option-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-title {
  font-size: var(--redo-body-small-text-size);
  font-weight: bold;
  padding: 4px 16px;
  color: var(--redo-dark-gray);
}

.option-content {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  padding-left: 24px;
  width: 100%;
  height: 100%;
}

.price-summary-title {
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.add-item {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.item-search {
  height: 400px;
  overflow: scroll;
}

.voided {
  color: var(--redo-colors-text-text-disabled);
  font-weight: normal;
  text-decoration: line-through !important;
}

.adjustment-bonus {
  display: flex;
  padding: 3px 11px;
  background-color: var(--redo-primary-color);
  color: var(--redo-text-color);
  border-radius: 50px;
  font-size: var(--redo-body-xsmall-text-size);
  margin-top: -2px;
}

.adjustment-fee {
  display: flex;
  padding: 3px 11px;
  background-color: var(--redo-dark-gray);
  color: var(--redo-text-color);
  border-radius: 50px;
  font-size: var(--redo-body-xsmall-text-size);
  margin-top: -2px;
}

.new-item-header {
  gap: var(--redo-spacing-xl);
}

.new-item-header-tabs {
  min-width: 150px;
  max-width: 15%;
  gap: var(--redo-spacing-xs);
  padding: var(--redo-spacing-xs);
}

.empty-exchange-order {
  text-align: center;
  font-size: var(--redo-body-medium-text-size);
  color: var(--redo-colors-text-text-disabled);
  padding: 10px;
}

.green-return-adjustment {
  display: flex;
  padding: 3px 11px;
  background-color: #3f7afe;
  color: var(--redo-text-color);
  border-radius: 50px;
  margin-top: -2px;
}

.green-return-select-container {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  align-items: center;
}

.green-return-select-title {
  font-size: 14px;
  font-weight: bold;
  color: #464646;
}

.green-return-text {
  font-size: 14px;
  font-weight: 300;
  color: #464646;
}

.green-return-image {
  border-radius: 10px;
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.modal-button {
  width: 150px;
}

.modal-footer-right {
  justify-content: right;
  display: flex;
  width: 100%;
}

.click-away-listener {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.return-type-modal {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--redo-colors-background-bg-primary);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  padding: 17px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border: solid #a6a9ba var(--redo-border-width);
  width: 234px;
  font-weight: normal;
  z-index: 100;
  margin-top: 5px;

  &.right-aligned {
    right: 15px;
    left: auto;
  }
}

.return-type-modal-header {
  display: flex;
  justify-content: space-between;
}

.return-type-modal-button {
  background: rgba(166, 169, 186, 0.15);
  padding: 11px;
  border-radius: 15px;
  font-size: 12px;
  padding-left: 18px;
  transition: 0.25s;
  text-align: left;
}

.return-type-modal-button:hover {
  background: #555760;
  color: white;
}

.table {
  width: 100%;

  tr:not(:first-child) {
    td,
    th {
      padding-top: 6px;
    }
  }

  tr:not(:last-child) {
    td,
    th {
      padding-bottom: 6px;
    }
  }
}

.order-link {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
  text-decoration: none;
}
.order-link:hover {
  text-decoration: underline;
}

.itemLink {
  text-decoration: none;
}

.itemLink:hover {
  text-decoration: underline;
}

.product {
  flex: 2;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.status {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--redo-spacing) * 2);
  font-size: var(--redo-body-medium-font-size);
  vertical-align: text-top;
  gap: 8px;
}

.external {
  margin-right: calc(var(--redo-spacing) * 2);
}

.coverage {
  display: inline-block;
  font-size: calc(var(--redo-spacing) * 2);
  vertical-align: text-top;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  padding: var(--redo-spacing) 0;
  margin-top: calc(var(--redo-spacing) * -1.5);
}

.padded-modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  padding: 16px;
  overflow-y: auto;
}

.processButtons {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.table-header-group {
  display: flex;
  flex-direction: column;
}

.table-header {
  font-size: var(--redo-body-small-text-size);
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
}

.table-description {
  font-size: var(--redo-body-xsmall-text-size);
  font-style: italic;
  padding-right: 8px;
  text-align: left;
}

.table-cell {
  font-size: var(--redo-body-small-text-size);
  padding-left: 8px;
  text-align: right;
  font-weight: 500;
}

.subsection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tracking-numbers {
  li {
    margin-left: 16px;
  }
}

.fraud-risk-container {
  display: flex;
  gap: var(--redo-spacing);
  font-size: var(--redo-body-small-text-size);
}

.risk-report-iframe {
  transition:
    height var(--redo-collapse-duration),
    width var(--redo-collapse-duration),
    min-width var(--redo-collapse-duration),
    max-width var(--redo-collapse-duration);
  top: 50%;
  left: 50%;
  position: absolute;
}

.full-width {
  width: 100%;
}
.full-height {
  height: 46px;
}

.create-labels-image {
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.item-card {
  width: 200px;
  background-color: var(--redo-colors-background-bg-tertiary);
}

.no-text-wrap {
  text-wrap: nowrap;
}

.trash {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.tooltip-link {
  text-decoration: underline;
}

.delete-icon-container {
  height: 24px;
}
.delete-icon {
  color: #f04438;
}

.new-items-section {
  overflow: visible;
}

.new-items {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-xl);
}

.address-space {
  margin-top: 16px;
}

.buttonContentNew {
  font-size: var(--redo-body-xsmall-text-size);
  font-weight: var(--typography-text-x xs-medium-font-weight);
  font-family: var(--redo-body-font-family);
  line-height: 16px;
}

.dropdown-option-text {
  font-size: var(--redo-body-xsmall-text-size);
  line-height: 16px;
  font-family: var(--redo-body-font-family);
  color: var(--redo-colors-text-text-primary-900);
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--redo-spacing);
  margin-left: auto;
  height: 28px;
  padding-left: var(--redo-spacing-md);
  flex-shrink: 0;
}

.button-dropdown-container {
  align-items: center;
  gap: var(--redo-spacing-md);
}

.button-dropdown {
  display: flex;
  height: 28px;
  align-items: center;
  border-radius: var(--redo-corner-small-radius);
}

.button-dropdown2 {
  z-index: 0;
}

.dropdown-option {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing-xs);
  justify-content: left;
}

.header-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--redo-spacing-md);
}

.top-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.orderNumbers {
  display: flex;
  align-items: center;
}

.top {
  justify-content: space-between;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin-left: 0;
  flex-shrink: 1;
}

.title-text {
  font-size: var(--redo-body-large-text-size);
  color: var(--redo-colors-text-text-primary-900);
  font-weight: var(--redo-font-weight-semibold);
  font-family: var(--redo-body-font-family);
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.subtitle-text {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
  font-family: var(--redo-body-font-family);
  font-weight: var(--redo-font-weight-regular);
}

.headerSubtitleSmall {
  display: inline-flex;
  align-items: center;
  margin-top: 0;
  position: relative;
}

.change-address {
  color: var(--redo-colors-orange-dark-500);
  text-decoration: underline;
  font-weight: 500;
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-xsmall-text-size);
  line-height: 16px;
}

.change-address:hover {
  color: var(--redo-colors-orange-dark-500) !important;
}

.link-color {
  color: var(--redo-colors-orange-dark-500);
}

.link-color:hover {
  color: var(--redo-colors-orange-dark-500) !important;
}

.headerSubtitleSmall:not(:last-child)::after {
  content: "•";
  margin: 0 8px;
  color: var(--redo-colors-foreground-fg-quarterary-500);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 6px;
  vertical-align: middle;
}

.questions {
  gap: 12px;
}

.question {
  gap: var(--redo-spacing-xs);
}

.subtitle-text {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
  font-family: var(--redo-body-font-family);
  font-weight: var(--redo-font-weight-regular);
  width: fit-content;
}

.action-button-text {
  font-size: var(--redo-body-xsmall-text-size);
  font-weight: 500;
  font-family: var(--redo-body-font-family);
  line-height: 16px;
}

.action-button {
  display: flex;
  height: 28px;
  gap: var(--redo-spacing-xs);
  justify-content: center;
  align-items: center;
}
.order-number {
  font-weight: 600;
}
.expand-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.expand-button-container {
  gap: 12px;
  display: flex;
  align-items: center;
}

.expanded {
  transform: rotate(0deg);
}

.hiddenValues {
  animation: slideDown 0.2s ease;
  padding-left: 20px;
  padding-right: var(--redo-spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-4xl);
}

.hiddenValuesClosing {
  animation: slideUp 0.2s ease;
  padding-left: 20px;
  padding-right: var(--redo-spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-4xl);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  cursor: pointer;
  position: relative;
}

.dropdown-icon {
  transition: transform 0.2s ease;
}

.dropdown-icon-open {
  transform: rotate(180deg);
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  padding: var(--redo-spacing);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: var(--redo-colors-background-bg-secondary);
}

.dropdown-text {
  flex: 1;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.product-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: var(--redo-body-medium-text-size);
  font-family: var(--redo-body-font-family);
  font-weight: 500;
}

.orderAndTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  min-width: 100px;
  flex-wrap: wrap;
  margin-right: var(--redo-spacing-md);
}

.orderNames {
  display: flex;
  align-items: center;
  min-width: 0;
}
