.filter-group-box {
  align-items: center;
}

.box-items {
  height: 24px;
  > * {
    border: 1px solid var(--redo-component-colors-utility-gray-utility-gray-200);
    &:not(:last-child) {
      border-right: 0px;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0px;
    }
  }
}

.icon-wrapper {
  > svg {
    height: 12px;
    width: 12px;
  }
  align-items: center;
}

.icon-secondary {
  color: var(--redo-component-colors-utility-gray-utility-gray-700);
}

.button {
  padding: var(--redo-spacing-xxs) var(--redo-spacing-lg);
  align-items: center;
  display: flex;
  &:hover {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-100
    );
  }
  &:active {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-200
    );
  }
}
