.customer-badge {
  cursor: pointer;
  max-width: 140px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.customer-badge {
  cursor: pointer;
}

.customer-info-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .edit-button {
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
    }
  }
}

.copyable-field {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.dropdown-container {
  width: 280px;
}

.dropdown-image {
  width: 100%;
}

.email {
  font-size: 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: none;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.input {
  font-size: var(--redo-body-xsmall-text-size);
  padding: 0px 4px;
}

.username {
  color: inherit;
}

.edit-assignee-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  .save-button {
    align-self: flex-end;
  }
}

.shopify-icon {
  margin-bottom: 4px;
}

.hidden {
  display: none;
}
