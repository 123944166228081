.drag-handle {
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    cursor: move;
    color: var(--redo-colors-background-bg-secondary-solid);
  }
}

.drag-handle-hovered {
  color: var(--redo-colors-background-bg-secondary-solid);
  cursor: grab;
}

.icon {
  width: 16px;
  height: 16px;
}

.column-item {
  max-height: 100%;
  width: 12rem;

  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }
}

.dropdown {
  width: 100%;
  min-width: 13rem; /* will collapse like a dying star without this min-width */
}
