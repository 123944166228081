.drawer {
  top: 0;
  right: -100%;
  height: 100%;
  position: fixed;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  width: 400px;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  background-color: var(--redo-colors-background-bg-primary);
  padding: calc(var(--redo-spacing) * 3);
  overflow: scroll;
}

.drawer-open {
  right: 0;
}

.divider {
  margin-left: -24px;
  margin-right: -24px;
}

.delete-option {
  color: var(--redo-colors-foreground-fg-error-primary);
}

.download-icon {
  width: 12px;
  height: 12px;
  color: var(--redo-colors-foreground-fg-quarterary-500);
}

.delete-icon {
  width: 12px;
  height: 12px;
}

.drawer-footer {
  margin-top: auto;
  flex-shrink: 0;
  max-height: calc(100% - 270px);
  overflow: scroll;
}

.highlighted {
  background-color: var(
    --redo-component-colors-utility-orange-dark-utility-orange-dark-400
  );
  padding: 2px;
  border-radius: var(--redo-corner-xsmall-radius);
}

.lighter-highlight {
  background-color: var(
    --redo-component-colors-utility-orange-dark-utility-orange-dark-200
  );
  padding: 2px;
  border-radius: var(--redo-corner-xsmall-radius);
}
