.input-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: var(--redo-colors-border-border-primary);
  background-color: var(--redo-colors-background-bg-primary);
  box-shadow: 0px 1px 2px 0px #1018280d;
  transition:
    border-color var(--redo-state-duration),
    outline-width var(--redo-state-duration);

  border-radius: var(--redo-corner-medium-radius);
  &.small {
    border-radius: var(--redo-xsmall-radius);
  }

  &:focus-within {
    border-color: var(--redo-colors-border-border-brand);
    outline: 4px solid var(--redo-primary-extra-light-color);
  }

  &.disabled {
    background-color: var(--redo-colors-background-bg-disabled_subtle);
    border-color: var(--redo-colors-border-border-disabled);
    color: var(--redo-colors-text-text-disabled);
  }

  &.error {
    border-color: var(--redo-colors-border-border-error);
  }
}

.textarea {
  &::placeholder {
    color: var(--redo-colors-text-text-placeholder);
  }
  display: flex;
  flex: 1 0 auto;
  resize: none;
}

.container-width {
  width: 320px;
}

.full-width {
  width: 100%;
}

.container {
  min-width: 320px;
  min-height: 180px;
  height: 180px;
}

.auto-expand {
  height: auto !important;
  min-height: auto !important;
}
