.event-action-dots {
  margin: 0 0 0 auto;
  float: right;
  position: relative;
  cursor: pointer;
}

.event-action-dots:focus-within .dropdown-menu {
  display: flex;
}

.dropdown-menu {
  flex-direction: column;
  justify-content: center;
  background: var(--redo-colors-foreground-fg-on-brand);
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid;
}

.modal {
  background: var(--redo-colors-background-bg-primary);
  border-radius: var(--redo-corner-medium-radius);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  height: 90%;

  transition:
    height var(--redo-collapse-duration),
    width var(--redo-collapse-duration),
    min-width var(--redo-collapse-duration),
    max-width var(--redo-collapse-duration);

  min-width: min(600px, 100%);
  max-width: 600px;
  width: 100%;
  align-items: center;
  text-align: center;
}
.email-preview {
  height: 100%;
  width: 100%;
}

.float-upper-right {
  position: absolute;
  right: 0;
  top: -44px;
}
