.recording-indicator {
  border-radius: 8px;
}

.recording-dot {
  height: 4px;
  width: 4px;
  background-color: var(--redo-colors-foreground-fg-disabled_subtle);
  border-radius: 50%;

  &.active {
    animation-name: recording-indicator;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes recording-indicator {
  0%,
  100% {
    background-color: var(--redo-colors-foreground-fg-error-primary);
  }
  50% {
    background-color: var(
      --redo-component-colors-utility-error-utility-error-200
    );
  }
}
