.bar-container {
  background-color: var(--redo-colors-background-bg-quaternary);
  width: 100%;
  display: grid;
  &.thick {
    height: 16px;
    border-radius: 4px;
  }
  &.thin {
    height: 8px;
    border-radius: 9999px;
  }
}

.fill-container {
  grid-row: 1;
  grid-column: 1;
  &.overflow-container {
    direction: rtl;
  }
}

.fill-bar {
  &.thick {
    border-radius: 4px;
  }
  &.thin {
    border-radius: 9999px;
  }

  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  height: 100%;
  &.regular {
    background-color: var(--redo-colors-foreground-fg-primary-900);
    width: var(--percentage);
  }
  &.overflow {
    background-color: var(--redo-component-colors-fg-action-primary);
    width: var(--overflow-percentage);
  }
}
