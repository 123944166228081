.actions {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  flex-wrap: wrap;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
  max-height: 21px;
}

.copyable-field {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
}

.overflow-visible {
  overflow: visible;
}

.pill-wrapper {
  overflow-x: auto;
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }
}

.products {
  display: flex;
  flex-direction: column;
  margin-top: var(--redo-spacing);
  gap: calc(var(--redo-spacing) * 3);
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}

.product-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
}

.product-top-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--redo-spacing);
}

.return-details {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.strong {
  font-size: 14px;
  font-weight: 600;
}

.variant-quantity-row {
  display: flex;
  flex-direction: row;
  color: var(--redo-light-gray);
}
