.help-circle-icon {
  color: var(--redo-component-colors-utility-gray-utility-gray-400);
  width: 20px;
  height: 20px;

  margin-top: 2px;
}

.help-circle-icon-container {
  height: 22px; /* 20px icon + 2px margin */
}

.alert-circle {
  color: var(--redo-colors-foreground-fg-warning-primary);
  width: 16px;
  height: 16px;
}

.checkbox-container {
  height: 40px; /* matches 40px item-image */
}

.modal-content {
  overflow-y: auto;
  padding-right: 20px; /* adjust for scrollbar */
  margin-right: -20px; /* adjust for scrollbar */
}

.divider {
  margin: 24px 0px;
}
