.table {
  display: grid;
  overflow: auto;
  min-width: 0px;
  min-height: 0px;
  background-color: var(--redo-colors-background-bg-primary);
  width: 100%;
}

.header-cell {
  position: sticky;
  top: 0;
  background-color: var(--redo-colors-background-bg-secondary);
  z-index: 1;
  &.white {
    background-color: var(--redo-colors-background-bg-primary);
  }
}

.row-wrapper {
  display: contents;
}

.loading-spinner-container {
  width: 30px;
  height: 60px;
}
