.width-container {
  width: 512px;
}

.container {
  border-radius: 12px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  background-color: var(--redo-colors-background-bg-primary);
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background-color: var(--redo-colors-background-bg-disabled_subtle);
  }
}

.hidden-input {
  display: none;
}

.fileContainer {
  border-radius: 12px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  background-color: var(--redo-colors-background-bg-primary);
  position: relative;
}

.cornerButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
}
