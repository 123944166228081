.icon {
  transition: size, color, transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &.primary {
    color: var(--redo-colors-text-text-primary-900);
  }
  &.secondary {
    color: var(--redo-colors-text-text-secondary-700);
  }
  &.tertiary {
    color: var(--redo-colors-text-text-tertiary-600);
  }
  &.quaternary {
    color: var(--redo-colors-text-text-quarterary-500);
  }
  &.quinary {
    color: var(--redo-colors-text-text-quinary-400);
  }
  &.disabled {
    color: var(--redo-colors-text-text-disabled);
  }
  &.error {
    color: var(--redo-colors-text-text-error-primary-600);
  }
  &.warning {
    color: var(--redo-colors-text-text-warning-primary-600);
  }
  &.success {
    color: var(--redo-colors-text-text-success-primary-600);
  }
  &.inherit {
    color: inherit;
  }
}
