.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 20;
  background-color: var(--redo-primary-color);
  color: #fff;
  width: 428px;
  padding: 16px;
  border-radius: var(--redo-corner-medium-radius);
  gap: 12px;
  align-items: center;
}

.link {
  text-decoration: underline;
  color: var(--redo-primary-color);
  cursor: pointer;
  text-underline-position: under;
}

.retry-button {
  background-color: var(--redo-colors-background-bg-alternate);
  color: #fff;
  padding: 8px 24px;
  border-radius: var(--redo-corner-small-radius);
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  cursor: pointer;
  background-color: #181818;
  color: #fff;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  top: -8px;
  right: -8px;
}
