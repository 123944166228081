.color-square {
  height: 16px;
  width: 16px;
}

.normal {
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-gray-utility-gray-700);
  background-color: var(--redo-component-colors-utility-gray-utility-gray-200);
}

/* pill.primary needs updating to new system */
.primary {
  border: var(--redo-border-width) solid white;
  background-color: var(--redo-primary-color);
}

.primary-light {
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-brand-utility-brand-700);
  background-color: var(
    --redo-component-colors-utility-brand-utility-brand-200
  );
}

.success {
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-success-utility-success-700);
  background-color: var(
    --redo-component-colors-utility-success-utility-success-200
  );
}

.danger {
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-error-utility-error-700);
  background-color: var(
    --redo-component-colors-utility-error-utility-error-200
  );
}

.neutral-yellow {
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-warning-utility-warning-700);
  background-color: var(
    --redo-component-colors-utility-warning-utility-warning-200
  );
}

/* TODO: (Josh) (figma) migrate the below to use the new figma color variables */

.neutral-blue {
  border: var(--redo-border-width) solid #175cd3;
  background-color: var(--redo-neutral-blue-color);
}

.light-pink {
  border: var(--redo-border-width) solid #c11574;
  background-color: #fdf2fa;
}

.solid-red {
  border: var(--redo-border-width) solid white;
  background-color: #d92d20;
}

.solid-yellow {
  border: var(--redo-border-width) solid white;
  background-color: #fdb022;
}

.solid-green {
  border: var(--redo-border-width) solid white;
  background-color: #067647;
}

.solid-navy {
  border: var(--redo-border-width) solid white;
  background-color: #3e4784;
}

.solid-light-blue {
  border: var(--redo-border-width) solid white;
  background-color: #1570ef;
}

.solid-pink {
  border: var(--redo-border-width) solid white;
  background-color: #c11574;
}

.solid-orange {
  border: var(--redo-border-width) solid white;
  background-color: #ef6820;
}

.solid-black {
  border: var(--redo-border-width) solid white;
  background-color: var(--redo-nav-color);
}
