.widget-container {
  background-color: var(--redo-colors-background-bg-primary);
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: 6px;
  width: 240px;
}

.icon-widget-container {
  background-color: var(--redo-colors-background-bg-primary);
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: 6px;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.phone-icon {
  height: 12px;
  width: 12px;
}

.status-dot {
  height: 8px;
  width: 8px;

  &.green {
    color: var(--redo-colors-foreground-fg-success-secondary);
  }
  &.red {
    color: var(--redo-colors-foreground-fg-error-secondary);
  }
  &.yellow {
    color: var(--redo-colors-foreground-fg-warning-secondary);
  }
  &.gray {
    color: var(--redo-colors-foreground-fg-disabled);
  }
}

.body-sections {
  > :not(:last-child) {
    border-bottom: 1px solid var(--redo-colors-border-border-primary);
  }
}

.icon-container {
  cursor: pointer;
}
