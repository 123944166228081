.actions {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  flex-wrap: wrap;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
  max-height: 21px;
}

.copyable-field {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.detail-column {
  display: flex;
  flex-direction: column;
}

.detail-row {
  display: flex;
  flex-direction: row;
}

.dropdown-title {
  font-weight: bold;
  padding: 8px;
}

.fulfillments {
  display: flex;
  flex-direction: column;
  margin-top: calc(var(--redo-spacing) * 2);
  gap: calc(var(--redo-spacing) * 3);
}

.fulfillment-details {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}

.icon {
  width: 16px;
  height: 16px;
}

.img-container {
  width: 44px;
  height: 46px;
  border-radius: var(--redo-corner-small-radius);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  flex-shrink: 0;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line-items {
  margin-top: calc(var(--redo-spacing) * 2);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}

.order-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.pill-wrapper {
  overflow-x: auto;
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }
}

.product {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  align-items: center;
}

.products {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 3);
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  width: 100%;
}

.product-top-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--redo-spacing);
}

.strong {
  font-size: 14px;
  font-weight: 600;
}

.variant-quantity-row {
  display: flex;
  flex-direction: row;
  color: var(--redo-light-gray);
}

.order-card {
  border-radius: var(--redo-spacing);
  border: 1px solid var(--redo-colors-border-border-primary);
  background-color: var(--redo-colors-background-bg-secondary);
}

.order-collapse-button,
.order-collapse-button button {
  > div {
    display: flex;
  }
  height: 24px;
  width: 24px;
}

.order-collapse-button-icon {
  width: 16px;
  height: 16px;
}

.wrapping-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  flex: 1 1 0%;
}

.tight-text {
  gap: 0;
  line-height: 16px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip-hidden {
  display: none;
}
