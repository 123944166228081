.walkthrough {
  width: 100%;
  max-width: 400px;
  position: relative;
  transition: all 0.3s ease;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--redo-colors-background-bg-quaternary);
  transition: all 0.3s ease;
  &.active {
    background-color: var(--redo-colors-background-bg-secondary-solid);
  }
}
