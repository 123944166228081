.timeline {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing-md);
}

.leftContent {
  position: relative;
  min-width: 32px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
}

.rightContent {
  flex: 1;
  gap: 4px;
  padding-bottom: var(--redo-spacing-xl);
}

.dot {
  width: 8px;
  height: 8px;
  padding-top: 4px;
  border: 2px solid black;
  background: var(--redo-colors-background-bg-primary);
  border-radius: 50%;
  margin-top: calc((var(--redo-line-height) * 1em - var(--point-size)) / 2);
}

.timelineLine {
  position: absolute;
  top: 12px;
  bottom: -8px;
  width: 2px;
  background: var(--redo-colors-border-border-primary);
}

.timelineItem:last-child .timelineLine {
  display: none;
}
