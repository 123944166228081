.assignee-badge {
  width: 100%;
  cursor: pointer;
}

.assignee-details {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.assignee-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .edit-assignee-info {
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
    }
  }
}

.dropdown-container {
  width: 200px;
}

.dropdown-image {
  width: 170px;
}

.edit-assignee-modal-container {
  position: absolute; /* Necessary to prevent gap around the empty div */
}

.input {
  font-size: var(--redo-body-xsmall-text-size);
  padding: 0px 4px;
}

.hidden {
  display: none;
}
