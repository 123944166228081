.spinner {
  width: 30px;
  height: 60px;
}

.spinnerContainer {
  background-color: var(--redo-colors-background-bg-primary);
  border: 1px solid var(--redo-colors-border-border-primary);
  border-top: 0px;
}

.borderlessSpinnerContainer {
  background-color: var(--redo-colors-background-bg-primary);
}

.table-container {
  flex: 1;
}

.filter-item {
  width: 11rem;
  > svg {
    height: 12px;
    width: 12px;
  }
  align-items: center;
}

.normal-cell-with-adjustable-border {
  overflow: hidden;
  width: 100%;
}

.close-icon {
  align-content: center;
  color: var(--redo-component-colors-utility-gray-utility-gray-500);
  height: 12px;
}

.filter-dropdown {
  cursor: pointer;
}

.filter-dropdown-item {
  &:hover {
    background-color: var(--redo-colors-background-bg-primary);
  }
}
