.filter-inc-dec {
  height: 22px !important;
  border-style: none !important;
  border-radius: 0px;
}

.left-border {
  border-left: 1px solid
    var(--redo-component-colors-utility-gray-utility-gray-200);
}

.right-border {
  border-right: 1px solid
    var(--redo-component-colors-utility-gray-utility-gray-200);
}

.string-input {
  border-style: none;
  height: 22px !important;
}
