.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.remove-padding {
  padding: 0;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}

.container {
  position: relative;
}

.quill-editor {
  border: none !important;
  font-size: var(--redo-body-small-text-size) !important;
  margin-bottom: 24px !important;
  margin-top: -16px !important;
  padding-left: 4px;
  padding-right: 4px;
}

.quill-container-small {
  border: none !important;
  font-size: var(--redo-body-small-text-size) !important;
  width: 100%;
}
.quill-editor-small {
  border: none !important;
  font-size: var(--redo-body-small-text-size) !important;
}

.quill-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none !important;
  width: 100%;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.quill-format-buttons {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  border: none !important;
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

/* Quill adds an unnecessary after element that creates unwanted space */
:global(.ql-snow.ql-toolbar:after) {
  display: none !important;
}

/* The quill-resize-image resizing toolbar for images is kinda disgusting lol */
:global(#editor-resizer .toolbar) {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

:global(.ql-editor.ql-blank::before) {
  color: var(--redo-colors-text-text-placeholder);
  font-family: var(--redo-body-font-family);
  font-style: normal;
}

/* FIXME: remove all :global from this file (other quill implementations exist that explicitly want to avoid using these styles) */
:global(.ql-blank) {
  padding: 12px !important;
}

:global(.ql-snow.ql-toolbar button.ql-active .ql-stroke) {
  stroke: var(
    --redo-component-colors-components-buttons-primary-button-primary-fg_hover
  ) !important;
}

:global(.ql-snow.ql-toolbar button.ql-active) {
  background-color: var(--redo-colors-gray-true-500) !important;
}

:global(.ql-snow.ql-toolbar button.ql-active .ql-fill) {
  fill: var(
    --redo-component-colors-components-buttons-primary-button-primary-fg_hover
  ) !important;
}

:global(.ql-stroke) {
  stroke: var(--redo-colors-gray-true-600) !important;
}

:global(.ql-snow.ql-toolbar button:hover) {
  color: var(--redo-colors-gray-true-600) !important;
}

:global(.ql-snow .ql-fill) {
  fill: var(--redo-colors-gray-true-600) !important;
}

:global(.ql-snow.ql-toolbar .ql-picker-label:hover) {
  color: var(--redo-colors-gray-true-600) !important;
}

:global(.ql-snow.ql-toolbar .ql-picker-label.ql-active) {
  color: var(--redo-colors-gray-true-600) !important;
}

:global(.ql-toolbar.ql-snow .ql-formats) {
  margin-right: 0px !important;
}

.fake-cursor {
  position: absolute;
  width: 1px;

  &.active {
    background-clip: content-box;
    background-color: black;
    /* Different browsers have different durations on their cursor blinking.
   * This value gets us close to Firefox's, but Chrome's is a little faster. */
    animation: blink 2.2s step-end infinite;
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
