.messages-card {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  flex: 1;
  gap: 0;
  padding: 0;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;
}

.created-date {
  white-space: nowrap;
}

.divider {
  width: inherit;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
}

.header-divider {
  margin: 0;
  display: flex;
  gap: var(--redo-spacing);
  width: 100%;
}

.messages-container-wrapper {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  &.with-padding {
    padding-top: calc(var(--redo-spacing) * 4);
  }
}

.summary-title {
  font-weight: 600;
  margin-bottom: var(--redo-spacing);
  height: calc(var(--redo-spacing) * 2);
  line-height: calc(var(--redo-spacing) * 2);
}

.messages-container {
  min-height: 0px;
  overflow-y: scroll;
  row-gap: 0px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.top-buttons {
  position: absolute;
  top: 10px;
  right: 0px;
  left: 0px;
  z-index: 10;
  pointer-events: none;
}

.show-left-panel-button {
  width: 38px;
  height: 38px; /* match height of the open panel button, which in turn matches the AI summary button exactly */
  padding: 0px !important;
}

.pointer-capturing-button-boxes {
  pointer-events: auto;
}

.show-more-button-container {
  position: relative;
  height: 4px;
  overflow: visible;
  border-bottom: 1px solid var(--redo-colors-border-border-primary);
}

.show-more-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -14px;
  left: 16px;
  z-index: 100;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0px !important;
}

.system-message-card-wrapper {
  border-bottom: 1px solid var(--redo-colors-border-border-primary);
}
