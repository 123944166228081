.activity-container {
  display: flex;
  align-items: center;
  gap: 0;
}

.typing-indicator-container {
  align-self: flex-end;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  height: 16px;
  background: var(--redo-colors-background-bg-primary);
  border: 1px solid var(--redo-button-secondary-border);
  padding: 3px 5px;

  border-radius: 9999px;
  transform: translate(-9px, 7px);
}

.typing-indicator-dot {
  border-radius: 50%;
  height: 4px;
  width: 4px;

  animation: wave 1s infinite;
}

.typing-indicator-dot:nth-child(1) {
  animation-delay: 0.3333s;
}
.typing-indicator-dot:nth-child(2) {
  animation-delay: 0.6666s;
}
.typing-indicator-dot:nth-child(3) {
  animation-delay: 0.9999s;
}

@keyframes wave {
  0% {
    transform: translateY(0px);
    background: var(--redo-colors-text-text-tertiary-600);
  }
  50% {
    transform: translateY(-4px);
    background: var(--redo-component-colors-utility-gray-utility-gray-400);
  }
  100% {
    transform: translateY(0px);
    background: var(--redo-colors-text-text-tertiary-600);
  }
}
