.item-details-container {
  font-size: var(--redo-body-xsmall-text-size);
  height: 22px;
  transition: 0.25s;
  overflow-y: hidden;
}

.details-caret {
  height: 18px;
  transition: 0.25s;
}

.details-expanded {
  height: auto;
}

.details-expanded .details-caret {
  transform: rotate(90deg);
}
