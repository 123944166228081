.phone-button {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  color: var(
    --redo-component-colors-components-buttons-secondary-button-secondary-fg
  );
  outline-color: var(
    --redo-component-colors-components-buttons-secondary-button-secondary-border
  );
  &:hover {
    color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-fg_hover
    );
    outline-color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-border_hover
    );

    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-100
    );
  }

  &:active {
    background-color: var(
      --redo-component-colors-components-buttons-secondary-bg_pressed
    );
  }
}
