.gray {
  color: var(--redo-light-gray);
}

.line-item {
  display: flex;
  justify-content: space-between;
}

.line-item-actions {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.line-item-image {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  border-radius: var(--redo-corner-small-radius);
  object-fit: cover;
}

.line-item-info {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.line-item-text {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  justify-content: center;
}

.title-row {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  align-items: center;
}

.warning {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}
