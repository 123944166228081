.action-buttons-container {
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
  padding: 16px;
}

.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  overflow-y: auto;
  padding: 16px;
}

.order-name {
  font-size: 18px;
  font-weight: 600;
}

.refund-option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.refund-options-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}
