.form-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 3);
  align-items: stretch;
}

.message {
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: var(--redo-body-small-text-size);
}
