.container {
  height: 16px;
  width: 4px;
}

.dots-loading {
  position: relative;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  animation: dot-animation 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dots-loading::before,
.dots-loading::after {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  animation-name: dot-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  content: "";
  position: absolute;
}

.dots-loading::before {
  top: 6px;
  animation-delay: 0s;
}

.dots-loading::after {
  top: -6px;

  animation-delay: 1s;
}

@keyframes dot-animation {
  0% {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-200
    );
  }
  50%,
  100% {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-400
    );
  }
}
