.icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medium.icon-wrapper {
  width: 10px;
  height: 10px;
}

.large.icon-wrapper {
  width: 12px;
  height: 12px;
}

.xsmall.button {
  width: 20px;
  height: 20px;
}

.small.button {
  width: 36px;
  height: 36px;
}
.medium.button {
  width: 40px;
  height: 40px;
}

.large.button {
  width: 44px;
  height: 44px;
}

.button {
  border-radius: 8px;
  cursor: pointer;
}

.button:hover.has-light-background {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.button:focus {
  box-shadow: 0 0 0 4px #98a2b324;
}

.has-light-background {
  color: var(--redo-colors-foreground-fg-quinary-400);
}

.has-light-background:hover {
  color: var(--redo-colors-foreground-fg-quinary_hover);
}

.has-dark-background {
  color: var(--redo-colors-foreground-fg-on-brand);
}
