.item-image {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.item-details-title {
  min-width: 80px;
}

.restock-item-switch-container {
  height: 40px; /* matches 40px item-image */
}
