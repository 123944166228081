@value row from './redo-table-row.module.css';
@value has-rows-selected from './redo-selectable-table.module.css';
@value selectable-table from './redo-selectable-table.module.css';

.overflowable-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0px;
  overflow: hidden;
}

.overflowable-text-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 0px;
}

.cell-container {
  padding: 16px;
}

.selectable-table .row-checkbox-cell,
.selectable-table .row .row-checkbox-cell {
  display: none;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.selectable-table.has-rows-selected .row-checkbox-cell,
.selectable-table .row:hover .row-checkbox-cell {
  display: block;
}
