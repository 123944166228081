.divider-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .line {
    border: 1px solid var(--redo-colors-border-border-secondary);
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
