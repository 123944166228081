.action-buttons-container {
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.action-modal-content-container {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  padding-bottom: calc(var(--redo-spacing) * 2);
}
