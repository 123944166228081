.card {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 400px;
  background-color: var(--redo-bg-primary_alt);
  border: 1px solid var(--redo-border-color-dark);
}

.icon-container {
  background-color: var(--redo-colors-background-bg-brand-secondary);
  width: 40px;
  height: 40px;
  padding: 10px;
}

.button {
  cursor: pointer;
}

.close-button-container {
  width: 20px;
  height: 20px;
  align-items: center;
  border-radius: 9999px;
  cursor: pointer;
  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }
}
