.containerMedium {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.containerSmall {
  display: flex;
  flex-direction: column;
  min-width: 100px;
}

.containerMicro {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  font-size: var(--redo-body-small-text-size);
}

.select {
  background-color: var(--redo-surface-medium-color);
  border-radius: 10px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  display: flex;
  justify-content: space-between;
  padding: 12px;
  font-size: var(--redo-body-medium-text-size);
  cursor: pointer;
  align-items: center;
  transition: background-color 200ms;
}

.select:hover {
  background-color: var(--redo-colors-border-border-secondary);
}

.dropdown {
  transition: opacity 250ms;
  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);
  margin: 8px;
  padding: 8px;
  border-radius: var(--redo-corner-small-radius);
}

.dropdown.background {
  background-color: var(--redo-colors-background-bg-primary);
}

.dropdown.enter,
.dropdown.exit-active {
  /* opacity: 0; */
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: var(--redo-corner-small-radius);
  cursor: pointer;
}

.option:hover {
  background-color: #f7f5ff;
}

.dropdown-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
