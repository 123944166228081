.add-button {
  color: var(--redo-component-colors-utility-gray-utility-gray-500);
}

.dropdown-container {
  width: 200px;
}

.pointer {
  cursor: pointer;
}

.icon {
  height: var(--redo-icon-medium-size);
  margin-right: 6px;
  width: var(--redo-icon-medium-size);
  vertical-align: middle;
}

.option-button {
  transition: background-color 300ms;
  &:hover,
  &.manual-focus {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }
  height: 24px;

  &.danger {
    color: var(--redo-colors-foreground-fg-error-primary);
  }
}

.input {
  width: 100%;
  font-size: var(--redo-body-xsmall-text-size);
  font-weight: var(--redo-font-weight-regular);
  padding: 0px 4px;
  &.border {
    border: var(--redo-border-width) solid
      var(--redo-colors-border-border-primary);
    border-radius: var(--redo-corner-small-radius);
    padding: var(--redo-spacing);
  }
  &.hide {
    display: none;
  }
}

.full-width {
  width: 100%;
}

.input-flex {
  width: fit-content;
}

.input-wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  gap: calc(var(--redo-spacing) * 1);
  flex-wrap: wrap;
}

.tags-flex {
  max-width: 100%;
}

.pill-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  &.show-overflow {
    overflow: visible;
  }
}

.x-button {
  color: inherit;
}

.check {
  color: var(--redo-colors-foreground-fg-brand-primary-600);
  height: 16px;
  width: 16px;
}

.footer-container {
  display: flex;
  padding: calc(var(--redo-spacing) * 3);
  justify-content: space-between;
}

.edit-modal-body {
  padding: calc(var(--redo-spacing) * 3);
}
