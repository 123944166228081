.action-buttons-container {
  display: flex;
  gap: var(--redo-spacing);
}

.action-modal-content-container {
  overflow-y: auto;
}

.create-tag-button {
  padding-left: 4px;
  margin: var(--redo-spacing) 0px calc(var(--redo-spacing) * 2) 0px;
}

.full-width-divider {
  width: 100%;
}

.has-create-tag-button {
  margin-left: auto;
}

.no-create-tag-button {
  padding: var(--redo-spacing);
  justify-content: center;
  width: 100%;
}

.tags-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  flex-wrap: wrap;
}

.footer-container {
  width: 100%;
}

.tags-modal-content-container {
  height: 460px;
  overflow-y: auto;
}
