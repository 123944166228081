.customer-activity-data-order-icon-container {
  border-radius: 16px;
  background-color: var(
    --redo-component-colors-utility-purple-utility-purple-50
  );
  height: 32px;
  width: 32px;
}

.customer-activity-data-order-icon {
  color: var(--redo-component-colors-utility-purple-utility-purple-600);
  height: 16px;
  width: 16px;
}

.customer-activity-data-return-icon-container {
  border-radius: 16px;
  background-color: var(--redo-component-colors-utility-blue-utility-blue-50);
  height: 32px;
  width: 32px;
}

.customer-activity-data-return-icon {
  color: var(--redo-component-colors-utility-blue-utility-blue-600);
  height: 16px;
  width: 16px;
}

.customer-activity-data-conversation-icon-container {
  border-radius: 16px;
  background-color: var(
    --redo-component-colors-utility-warning-utility-warning-50
  );
  height: 32px;
  width: 32px;
}

.conversation-activity-data-conversation-icon-container {
  border-radius: 16px;
  background-color: var(
    --redo-component-colors-utility-warning-utility-warning-50
  );
  height: 24px;
  width: 24px;
}

.customer-activity-data-conversation-icon {
  color: var(--redo-component-colors-utility-warning-utility-warning-600);
  height: 16px;
  width: 16px;
}

.customer-activity-data-claim-icon-container {
  border-radius: 16px;
  background-color: var(--redo-component-colors-utility-pink-utility-pink-50);
  height: 32px;
  width: 32px;
}

.customer-activity-data-claim-icon {
  color: var(--redo-component-colors-utility-pink-utility-pink-600);
  height: 16px;
  width: 16px;
}

.customer-activity-shopping-icon-container {
  overflow: hidden;
  height: 40px;
  width: 40px;
}

.message-bubble {
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-corner-medium-radius);
  border-top-left-radius: 0px;
  box-shadow: 0px 1px 2px var(--redo-shadow-color-primary);
  background-color: var(--redo-colors-background-bg-primary);
}

.message-bubble-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.base-customer-activity-card-container {
  cursor: default;
  border-radius: var(--redo-corner-xsmall-radius);
}

.base-customer-activity-card-container-hovered {
  background-color: var(--redo-colors-background-bg-secondary_hover);
}

.base-customer-activity-card-arrow-right-container {
  height: 24px;
  width: 24px;
}

.base-customer-activity-card-arrow-right {
  height: 16px;
  width: 16px;
}
