.pill {
  display: inline-block;
  border-radius: 16px;
  padding: 4px 20px;
  line-height: 1.5;
  text-transform: capitalize;
  text-align: center;
  white-space: nowrap;
  font-family: var(--redo-body-font-family);
  &.no-capitalize {
    text-transform: none;
  }
}

.pill.normal {
  color: var(--redo-component-colors-utility-gray-utility-gray-700);
  background-color: var(--redo-component-colors-utility-gray-utility-gray-50);
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-gray-utility-gray-200);
}

/* pill.primary needs updating to new system */
.pill.primary {
  color: white;
  background-color: var(--redo-primary-color);
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-brand-utility-brand-400);
}

.pill.primary-light {
  color: var(--redo-component-colors-utility-brand-utility-brand-700);
  background-color: var(--redo-component-colors-utility-brand-utility-brand-50);
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-brand-utility-brand-200);
}

.pill.success {
  color: var(--redo-component-colors-utility-success-utility-success-700);
  background-color: var(
    --redo-component-colors-utility-success-utility-success-50
  );
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-success-utility-success-200);
}

.pill.danger {
  color: var(--redo-component-colors-utility-error-utility-error-700);
  background-color: var(--redo-component-colors-utility-error-utility-error-50);
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-error-utility-error-200);
}

.pill.neutral-yellow {
  color: var(--redo-component-colors-utility-warning-utility-warning-700);
  background-color: var(
    --redo-component-colors-utility-warning-utility-warning-50
  );
  border: var(--redo-border-width) solid
    var(--redo-component-colors-utility-warning-utility-warning-200);
}

/* TODO: (Josh) (figma) migrate the below to use the new figma color variables */

.pill.neutral-blue {
  color: #175cd3;
  background-color: var(--redo-neutral-blue-color);
  border: var(--redo-border-width) solid #b2ddff;
}

.pill.solid-white {
  background-color: white;
  border: var(--redo-border-width) solid #d9d9d9;
  color: #424242;
}

.pill.light-pink {
  color: #c11574;
  background-color: #fdf2fa;
  border: var(--redo-border-width) solid #fcceee;
}

.pill.solid-red {
  color: white;
  background-color: #d92d20;
  border: var(--redo-border-width) solid #f97066;
}

.pill.solid-yellow {
  color: white;
  background-color: #fdb022;
  border: var(--redo-border-width) solid #fedf89;
}

.pill.solid-green {
  color: white;
  background-color: #067647;
  border: var(--redo-border-width) solid #47cd89;
}

.pill.solid-navy {
  color: white;
  background-color: #3e4784;
  border: var(--redo-border-width) solid #717bbc;
}

.pill.solid-light-blue {
  color: white;
  background-color: #1570ef;
  border: var(--redo-border-width) solid #53b1fd;
}

.pill.solid-pink {
  color: white;
  background-color: #c11574;
  border: var(--redo-border-width) solid #f670c7;
}

.pill.solid-orange {
  color: white;
  background-color: #ef6820;
  border: var(--redo-border-width) solid #f7b27a;
}

.pill.solid-black {
  color: white;
  background-color: var(--redo-nav-color);
  border: var(--redo-border-width) solid #a3a3a3;
}

.pill.medium {
}

.pill.small {
  border-radius: var(--redo-corner-small-radius);
  padding: 4px 14px;
  font-size: var(--redo-body-xsmall-text-size);
}

.pill.xsmall {
  border-radius: 6px;
  padding: 2px 6px;
  font-size: var(--redo-body-xsmall-text-size);
}

.pill.near-square {
  border-radius: var(--redo-xsmall-radius);
  padding: 4px 8px;
  font-size: var(--redo-body-xsmall-text-size);
  vertical-align: middle;
}

.pill.shadow {
  box-shadow: 0px 1px 2px 0px #1018280d;
}
