.checkbox-cell {
  height: 100%;
  width: 100%;
}

.has-rows-selected {
}

.selectable-table {
}
