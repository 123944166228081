/* commandBarModal.module.css */
.commandBarModal {
  width: 640px !important;
  height: 512px !important; /* Changed from max-height to fixed height */
  background: var(--redo-colors-background-bg-primary);
  border-radius: 8px;
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* globalSearch.module.css */
.searchInputContainer {
  width: 100%;
  background: var(--redo-colors-foreground-fg-quarterary);
}

.searchIcon {
  width: 20px;
  height: 20px;
  color: var(--redo-colors-foreground-fg-quarterary);
  fill: var(--redo-colors-foreground-fg-quarterary);
}

.searchInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: var(--redo-body-medium-text-size);
  background: transparent;
  color: var(--redo-colors-foreground-fg-quarterary);

  &::placeholder {
    color: var(--redo-colors-text-text-placeholder);
  }
}

.shortcutContainer {
  padding: 2px 6px;
  background: var(--redo-colors-background-bg-secondary);
  border-radius: var(--redo-corner-small-radius);
}

.commandIcon {
  width: 16px;
  height: 16px;
  color: var(--redo-colors-text-text-secondary);
}

.shortcutText {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-secondary);
}

.resultsSection {
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
}

.menuItem {
  border-radius: var(--redo-corner-md-radius);
  cursor: pointer;
}

.scrollContainer {
  max-height: 182px;
  overflow-y: auto;
}
