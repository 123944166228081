.reschedule-modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  padding: 0px 24px 0px 24px;
  overflow-y: auto;
}

.info-text {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-color-text-tertiary);
}

.action-buttons {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  padding: 24px;
  width: 100%;
  &.cancel-buttons {
    flex-direction: column;
    align-items: center;
  }
}

.bold-card-text {
  color: var(--redo-color-text-primary);
  font-weight: 500;
}
