.bubble-content {
  font-size: var(--widget-message-font-size, var(--redo-body-xsmall-text-size));
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  a {
    text-decoration: underline;
  }

  &.light {
    background-color: var(--redo-colors-background-bg-primary);
    border: var(--redo-border-width) solid
      var(--redo-colors-border-border-primary);
  }

  &.dark {
    background-color: var(--redo-colors-background-bg-alternate);
    color: var(--redo-colors-text-text-white);
  }

  &.left {
    border-top-left-radius: 0;
  }

  &.right {
    border-top-right-radius: 0;
  }
}

.loading-bubble {
  width: fit-content;
  padding: 9px;

  background-color: var(--redo-colors-background-bg-secondary);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);

  &.left {
    border-top-left-radius: 0;
  }

  &.right {
    border-top-right-radius: 0;
  }

  .loading-dot-container {
    width: 4px;
    height: 8px;

    .dot {
      position: relative;
      top: 4px;
      animation: moveUpDown 1.2s linear infinite;

      &.first-dot {
        animation-delay: 0s;
      }
      &.second-dot {
        animation-delay: 0.3s;
      }
      &.third-dot {
        animation-delay: 0.6s;
      }
    }
  }
}

@keyframes moveUpDown {
  0%,
  25%,
  100% {
    transform: translateY(0);
    background-color: var(--redo-colors-foreground-fg-tertiary-600);
  }
  8% {
    transform: translateY(-4px);
    background-color: var(--redo-colors-foreground-fg-quinary-400);
  }
  17% {
    transform: translateY(2px);
    background-color: var(--redo-colors-foreground-fg-tertiary-600);
  }
}

.loading-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
