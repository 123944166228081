.rich-text-input {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 10px;
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.attachment-container {
  position: relative;

  .remove-file-button {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: var(--redo-surface-danger-light-color);
    color: var(--redo-colors-base-black);
    border-radius: 50%;
    cursor: pointer;
    width: calc(var(--redo-spacing) * 3);
    height: calc(var(--redo-spacing) * 3);
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  overflow-x: auto;
  padding: 12px 12px 16px 0;

  div:hover {
    .remove-file-button {
      display: flex;
    }
  }
}
