.strong {
  font-size: 14px;
  font-weight: 600;
}

.gray {
  color: var(--redo-colors-text-text-disabled);
}

.item-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  img {
    object-fit: cover;
    width: 64px;
    height: 100%;
    border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  }
}

.item-details {
  display: flex;
  flex-direction: column;
}

.cart-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}
