.messages-card {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  flex: 1;
  gap: 0;
  padding: 0;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;
}

.created-date {
  white-space: nowrap;
}

.divider {
  width: inherit;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
}

.header-divider {
  margin: 0;
  display: flex;
  gap: var(--redo-spacing);
  width: 100%;
}

.messages-container-wrapper {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  scrollbar-width: thin;
  &.with-padding {
    padding-top: calc(var(--redo-spacing) * 4);
  }
}

.messages-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  gap: calc(var(--redo-spacing) * 1.5);
}

.top-buttons {
  position: absolute;
  top: 10px;
  right: 0px;
  left: 0px;
  z-index: 10;
}

.show-left-panel-button {
  width: 38px;
  height: 38px; /* match height of the open panel button, which in turn matches the AI summary button exactly */
  padding: 0px !important;
}
