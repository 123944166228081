.card-padding {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.item-container {
  padding: 16px;
  background-color: var(--redo-colors-background-bg-secondary);
  border-radius: var(--redo-corner-xsmall-radius);
  border-color: var(--redo-colors-border-border-primary);
  border-width: 1px;
  border-style: solid;
}
