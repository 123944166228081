.images {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
}

.link {
  color: var(--redo-colors-orange-dark-500);
  text-decoration: underline;
  font-size: var(--redo-body-xsmall-text-size);
  font-family: var(--redo-body-font-family);
}

.link:hover {
  color: var(--redo-colors-orange-dark-500) !important;
}

.pill-content {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.pill-icon {
  width: 12px;
  height: 12px;
}

.sales-channel-wrapper {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
