.picker-modal {
  min-width: 400px;
  width: 400px;
  max-width: 400px;
  position: relative;
}

.x-button {
  position: absolute;
  top: 16px;
  right: 16px;
}
