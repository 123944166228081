.activity-item {
  padding: 8px;
  border-bottom: 1px solid var(--color-border);
}

.activity-item:last-child {
  border-bottom: none;
}

.timeline-container {
  padding-right: var(--redo-spacing-lg);
  padding-left: var(--redo-spacing-lg);
  position: relative;
}

.timeline-item {
  position: relative;
}

.external-link {
  color: var(--redo-colors-orange-dark-500);
}

.external-link:hover {
  color: var(--redo-colors-orange-dark-500) !important;
  text-decoration: underline;
}

.timeline-line {
  width: 2px;
  background-color: var(--redo-colors-border-border-secondary);
  position: absolute;
  top: 36px;
  bottom: 4px;
}

.avatar-container {
  position: relative;
  z-index: 1;
  background: white;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.activity-item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.input-container {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 56px;
}

.input {
  flex: 1;
  height: 100%;
}

.message-box {
  background-color: white;
  border: 1px solid var(--redo-colors-border-border-secondary);
  padding: 10px var(--redo-spacing-lg);
  border-radius: var(--redo-spacing-none) var(--redo-spacing-md)
    var(--redo-spacing-md) var(--redo-spacing-md);
  margin-top: 4px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
  position: relative;
}

.comment-image {
  position: relative;
  border-radius: var(--redo-spacing-xs);
  overflow: hidden;
  max-width: 120px;
  max-height: 120px;
}

.messageInputWrapper :global(form) {
  padding: 0 !important;
}

.dot-container {
  position: relative;
  z-index: 1;
  background: #f5f5f5;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  height: 16px;
  width: 16px;
  color: var(--redo-color-text-tertiary);
}

.left-content {
  padding-bottom: var(--redo-spacing-xs);
  align-items: center;
}

.right-content {
  padding-bottom: var(--redo-spacing-4xl);
  flex: 1;
}

.timeline-item:last-child .timeline-line {
  display: none;
}

.delete-button {
  position: relative;
  align-self: flex-start;
}
