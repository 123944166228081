.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer {
  justify-content: end;
  gap: 8px;
  display: flex;
  width: 100%;
}

.address-fields {
  display: flex;
  gap: 8px;
}

.grow {
  flex-grow: 1;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subheader {
  font-weight: bold;
  margin: 16px 0px;
}

.customer-information-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.selection-wrapper {
  margin: 16px 0px;
}

.item-selection-header {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px;
  font-weight: bold;
}

.select-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 120px;
}

.spinner {
  width: 32px;
}

.icon {
  cursor: pointer;
}

.search {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-footer {
  justify-content: end;
  gap: 8px;
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.modal-content {
  overflow-y: auto;
  padding-right: 32px;
}

.line-item-wrapper {
  display: flex;
  gap: 16px;
  justify-content: start;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--redo-light-gray);
  padding-bottom: 8px;
}

.line-item-returned-wrapper {
  display: flex;
  gap: 16px;
  justify-content: start;
  margin-bottom: 8px;
  opacity: 70%;
  border-bottom: 1px solid var(--redo-light-gray);
  padding-bottom: 8px;
}

.item-details {
  margin: 12px 0px;
}

.line-item-image {
  width: 72px;
  height: 72px;
  min-height: 72px;
  min-width: 72px;
  border-radius: 10px;
  object-fit: cover;
}

.error {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}

.add-details {
  margin-left: auto;
  align-self: center;
}

.flex {
  display: flex;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.uploaded-image {
  border-radius: var(--redo-corner-small-radius);
  height: 96px;
  width: 96px;
  min-height: 96px;
  min-width: 96px;
  cursor: pointer;
  object-fit: cover;
}

.close-button {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: #eeeef1;
  height: 24px;
  width: 24px;
  border-radius: 16px;
  cursor: pointer;
  padding: 4px;
}

.image-button {
  background-color: var(--redo-surface-medium-color);
  border-radius: var(--redo-corner-small-radius);
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a6a9ba;
  cursor: pointer;
}

.uploaded-image-wrapper {
  position: relative;
  height: 96px;
  width: 96px;
  min-height: 96px;
  min-width: 96px;
}

.images-wrapper {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.multiple-choice-answers-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.multiple-choice-answer {
  width: 49%;
}

.question {
  font-weight: bold;
  margin: 8px 0px;
}

.label {
  font-weight: bold;
  font-size: var(--redo-body-small-text-size);
}

.customer-details {
  display: flex;
  justify-content: space-between;
}

.order-selection {
  margin-top: 16px;
}

.order-button {
  width: 100%;
}

.order-label {
  text-align: left;
}
