.lightbox-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.lightbox-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;
  background-color: var(--redo-colors-background-bg-primary);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--redo-surface-dark-color);
  }

  &:active {
    background-color: var(--redo-light-gray);
  }
}

.close-button-icon {
  color: var(--redo-colors-base-black);
  width: 18px;
  height: 18px;
}
