.right-content {
  width: 100%;
  padding: 0;
  display: flex;
  background-color: var(--redo-colors-background-bg-primary);
  border-left: 1px solid var(--redo-colors-border-border-secondary);
  height: 100vh;
  flex-direction: column;
  gap: 0;
  overflow: auto;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--redo-colors-background-bg-primary);
  z-index: 1;
  height: 44px;
  min-height: 44px;
}

.panel-wrapper {
  > * {
    border-bottom: 1px solid var(--redo-colors-border-border-primary);
    padding: 16px;
  }
}

.post-preview {
  width: calc(var(--redo-spacing) * 7);
  height: calc(var(--redo-spacing) * 7);
  border-radius: var(--redo-corner-small-radius);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  flex-shrink: 0;
}

.post-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapping-text {
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.csat-score {
  > svg {
    height: 16px;
    width: 16px;
  }
  color: var(--redo-colors-yellow-400);
}
