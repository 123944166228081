.container {
  position: relative;
  width: 100%;
  max-width: 272px;
  min-width: 272px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.image {
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
}

.close-x {
  position: absolute;
  top: 8px;
  right: 8px;
}
