.aciton-buttons {
  position: absolute;
  bottom: 16px;
  left: 50%;
  z-index: 1001;
}

.block {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 16px;
  gap: 10px;
  position: absolute;
  width: 280px;
  outline: var(--redo-border-width) solid #ddd;
  transition:
    left var(--redo-collapse-duration),
    top var(--redo-collapse-duration),
    background-color var(--redo-state-duration),
    outline var(--redo-state-duration);

  &:enabled {
    cursor: pointer;
  }

  &:not(.selected):not(.highlighted):enabled:hover,
  &:not(.selected):not(.highlighted).hover {
    outline-color: var(--redo-colors-text-text-disabled);
  }

  &.selected {
    outline-color: #6952de;
    background-color: var(--redo-primary-extra-light-color);
    box-shadow: 0 0 8px 8px var(--redo-primary-extra-light-color);
  }

  &.highlighted {
    outline: 2px dashed #6952de;

    &:hover {
      background-color: #c6bdef;
    }
  }

  &.small .block-title {
    text-align: center;
  }
}

.block-content {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  flex: 1;
  overflow: hidden;
}

.block-title {
  color: #181818;
  font-size: var(--redo-body-large-text-size);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.block-description {
  color: var(--redo-dark-gray);
  font-size: var(--redo-body-small-text-size);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-icon {
  background-color: #e8e8e8;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6a6d7a;
}

.border {
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-corner-small-radius);
}

.edge {
  position: absolute;
  transition:
    left 500ms,
    top 500ms;
}

.edge-label {
  background-color: var(--redo-surface-dark-color);
  color: #444;
  position: absolute;
  font-size: var(--redo-body-small-text-size);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edge-path {
  fill: none;
  stroke: #aaa;
  stroke-width: 1.5px;
}

.error {
  height: 16px;
  width: 16px;
  color: #ff7765;
}

.flowchart {
  position: relative;
  overflow: hidden;
  cursor: grab;
  user-select: none;
  min-height: 250px;
  height: 100%;
}

.flowchart-content {
  transform-origin: 0 0;
}

.icon {
  width: 20px;
  height: 20px;
}

.nav-button > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-buttons {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1000;
  display: flex;

  .center {
    border-radius: 0;
  }

  .left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
