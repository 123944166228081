.avatar-container {
  border-radius: 50%;

  position: relative;
  outline: 1px solid #00000014;
  outline-offset: -1px;

  background-color: var(--redo-component-colors-components-avatars-avatar-bg);

  &.image {
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
  }

  &.xxs {
    width: 16px;
    height: 16px;
  }
  &.xs {
    width: 24px;
    height: 24px;
  }
  &.sm {
    width: 32px;
    height: 32px;
  }
  &.md {
    width: 40px;
    height: 40px;
  }
  &.lg {
    width: 48px;
    height: 48px;
  }
  &.xl {
    width: 56px;
    height: 56px;
  }
  &.xxl {
    width: 64px;
    height: 64px;
  }

  .icon svg {
    color: var(--redo-colors-foreground-fg-quarterary-500);
    &.xxs {
      width: 12px;
      height: 12px;
    }
    &.xs {
      width: 16px;
      height: 16px;
    }
    &.sm {
      width: 20px;
      height: 20px;
    }
    &.md {
      width: 24px;
      height: 24px;
    }
    &.lg {
      width: 28px;
      height: 28px;
    }
    &.xl {
      width: 32px;
      height: 32px;
    }
    &.xxl {
      width: 32px;
      height: 32px;
    }
  }
}

.status {
  position: absolute;

  &.xxs {
    top: 10px;
    left: 10px;
  }
  &.xs {
    top: 18px;
    left: 18px;
  }
  &.sm {
    top: 24px;
    left: 24px;
  }
  &.md {
    top: 30px;
    left: 30px;
  }
  &.lg {
    top: 36px;
    left: 36px;
  }
  &.xl {
    top: 42px;
    left: 42px;
  }
  &.xxl {
    top: 50px;
    left: 50px;
  }

  .presence-dot {
    outline: 1.5px solid var(--redo-colors-background-bg-secondary_hover);
    border-radius: 50%;
    &.xxs,
    &.xs {
      width: 6px;
      height: 6px;
    }
    &.sm {
      width: 8px;
      height: 8px;
    }
    &.md {
      width: 10px;
      height: 10px;
    }
    &.lg {
      width: 12px;
      height: 12px;
    }
    &.xl {
      width: 14px;
      height: 14px;
    }
    &.xxl {
      width: 16px;
      height: 16px;
    }

    &.green {
      background-color: var(--redo-colors-foreground-fg-success-secondary);
    }
    &.red {
      background-color: var(--redo-colors-foreground-fg-error-primary);
    }
    &.yellow {
      background-color: var(--redo-colors-foreground-fg-warning-secondary);
    }
    &.gray {
      background-color: var(--redo-colors-foreground-fg-disabled_subtle);
    }
  }
}
