.modal {
  background: var(--redo-colors-background-bg-primary);
  border-radius: var(--redo-corner-medium-radius);
  left: 50%;
  max-width: 900px;
  min-width: min(600px, 100%);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  max-height: 90%;
  overflow: auto;
  transition:
    height var(--redo-collapse-duration),
    width var(--redo-collapse-duration),
    min-width var(--redo-collapse-duration),
    max-width var(--redo-collapse-duration);

  &.skinny {
    min-width: min(300px, 100%);
    max-width: 400px;
    width: 40%;
  }

  &.small {
    min-width: min(400px, 100%);
    max-width: 500px;
    width: 50%;
  }

  &.medium {
    min-width: min(600px, 100%);
    max-width: 700px;
    width: 60%;
  }

  &.large {
    min-width: min(800px, 100%);
    max-width: 900px;
    width: 80%;
  }

  &.flexible {
    min-width: min(0px, 100%);
    max-width: 100%;
    width: auto;
  }

  &.centered {
    align-items: center;
    text-align: center;
  }
}

.no-padding {
  padding: 0;
  gap: 0;
}

.smallPadding {
  padding: calc(var(--redo-spacing) * 2);
}

.mediumPadding {
  padding: calc(var(--redo-spacing) * 3);
}

.largePadding {
  padding: calc(var(--redo-spacing) * 4);
}

.modal-container {
  overflow: scroll;
  position: absolute;
  top: 10%;
  left: 10%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header-border {
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  padding-bottom: calc(var(--redo-spacing) * 3);
}

.header-padding {
  padding: calc(var(--redo-spacing) * 2);
}

/* Groups the modal icon and title together */
.icon-and-title {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  color: var(--redo-colors-text-text-primary-900);
}

.header-default {
  font-family: var(--redo-title-font-family);
  font-size: var(--redo-title-large-text-size);
}

.header-medium {
  font-size: var(--redo-body-large-text-size);
  font-weight: var(--redo-font-weight-semibold);
}

.title {
  color: var(--redo-colors-text-text-secondary-700);
}

.subtitle {
  color: var(--redo-colors-text-text-secondary-700);
  font-family: var(--redo-body-font-family);
  padding-top: 5px;
}

.footer {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  justify-content: center;
}

.footer-border {
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}
