.container {
  overflow-y: auto;
  overflow-x: visible;
}

.slider-container {
  overflow-y: auto;
  overflow-x: visible;
  position: relative;
}

.slider-overflow {
  overflow: visible !important; /* !important is needed to override keen-slider's styles */
}

.slide {
  width: auto;
}

.arrow-container {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 50%;
  cursor: pointer;
  /* Colors hard-coded to allow for opacity adjustment */
  background-color: #292929d3;
  &.disabled {
    background-color: #29292954;
    cursor: default;
  }
}

.left-arrow-container {
  composes: arrow-container;
  left: 2%;
}

.right-arrow-container {
  composes: arrow-container;
  right: 2%;
}

.arrow {
  color: var(--redo-colors-foreground-fg-disabled_subtle);
}

.dot-container {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.dot {
  border: none;
  border-radius: 9999px;
  width: 8px;
  height: 8px;
  background: var(--redo-colors-gray-true-200);
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: var(--redo-colors-foreground-fg-brand-primary-600);
}
