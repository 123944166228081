.button-content {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  justify-content: center;
}

.dropdown-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
}

.label-button {
  width: 100%;
}

.download-button {
  width: 100%;
}

.pickup-button {
  width: 100%;
}

.icon {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.shipment-counter {
  white-space: nowrap;
}
