.page {
  width: 100%;
  /* TODO: remove this once we make the review page actually good */
  background-color: var(--redo-colors-background-bg-primary);
  flex: 1;
}

.content-wrapper {
  width: 100%;
  display: flex;
  gap: calc(var(--redo-spacing) * 3);
  padding: 0px 108px;
  padding-bottom: 140px;
}

.left-content {
  width: 66%;
  min-width: 600px;
}

.summary-cards {
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.address-card {
  border-radius: 10px;
  padding: 24px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: var(--redo-border-width) solid #a6a9ba;
}

.summary-card {
  background-color: var(--redo-surface-medium-color);
  border-radius: 10px;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary-card-horizonal {
  background-color: var(--redo-surface-light-primary-color);
  border-radius: 10px;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary-card-pickup {
  background-color: var(--redo-surface-light-primary-color);
  border-radius: 10px;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pickup-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.summary-card-content {
  width: 100%;
}

.summary-card-item {
  display: flex;
  justify-content: space-between;
  &.center {
    align-items: center;
    width: 100%;
  }
  &.left {
    align-items: flex-start;
    width: 100%;
  }
}

.summary-card-item-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.summary-card-item-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff7765;
}

.covered {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--redo-colors-background-bg-secondary);
  color: var(--redo-component-colors-utility-gray-utility-gray-900);
  border-radius: var(--redo-corner-small-radius);
  height: 40px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.brand {
  color: var(--redo-primary-color);
}

.logo {
  height: 24px;
  margin-right: 8px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  margin-bottom: 12px;
}

.card {
  margin-top: 16px;
  padding-bottom: 8px;
  width: 95%;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
}

.card-information {
  display: flex;
  align-items: center;
  margin-top: -24px;
}

.code {
  display: inline-block;
  font-size: var(--redo-body-small-text-size);
  background: lightyellow;
  border-radius: 2px;
  margin-left: 8px;
  padding: 2px 8px;
}

.info-icon {
  color: #a6a9ba;
}

.modal-container {
  margin: 8px 0px;
}
.modal {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: var(--redo-corner-small-radius);
  border: var(--redo-border-width) solid #a6a9ba;
  padding: 48px 48px 16px 48px;
  min-height: 380px;
  background-color: #fff;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
}

.card-image {
  width: 164px;
  min-width: 164px;
  aspect-ratio: var(--image-aspect-ratio-override, 1/1);
  border-radius: 10px;
  object-fit: cover;
}

.price {
  font-size: var(--redo-body-large-text-size);
  font-weight: bold;
}

.pickup-price {
  font-size: var(--redo-body-medium-text-size);
  font-weight: bold;
}

.pickup-button {
  background-color: #5f45e2 !important;
}

.price.voided {
  color: var(--redo-colors-text-text-disabled);
  font-weight: normal;
  text-decoration: line-through;
}

.card-items {
  display: flex;
  margin-left: 12px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  font-size: var(--redo-body-medium-text-size);
}

.subheader {
  font-weight: bold;
  font-size: 18px;
  color: #181818;
}

.address-text {
  color: #737373; /* passes WCAG AA on a #F9F9F9 background. Not needed for white bgs or text >18px */
  font-size: var(--redo-body-medium-text-size);
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.returning-disclaimer {
  color: #737373; /* passes WCAG AA on a #F9F9F9 background. Not needed for white bgs or text >18px */
  font-size: var(--redo-body-medium-text-size);
  margin-top: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.line {
  border: var(--redo-border-width) inset #a6a9ba;
  width: 100%;
}

.input-row {
  background-color: var(--redo-surface-medium-color);
  border-radius: var(--redo-corner-small-radius);
  margin-top: 24px;
}

.input {
  font-size: var(--redo-body-large-text-size);
  margin-top: 8px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  gap: 8px;
}

.error {
  color: #ff7765;
}

.summary-header {
  color: #181818;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.summary-subheader {
  color: #181818;
  font-size: var(--redo-body-medium-text-size);
  font-weight: bold;
}

.summary-text {
  color: #181818;
  font-size: var(--redo-body-medium-text-size);
}

.summary-text-light {
  color: #8e8e8e;
  font-size: var(--redo-body-medium-text-size);
}

.summary-text-bold {
  color: #181818;
  font-size: var(--redo-body-medium-text-size);
  font-weight: bold;
}

.summary-disclaimer {
  color: #888990;
  font-size: var(--redo-body-small-text-size);
  margin-top: 2px;
}

.pickup-input-description {
  color: #888990;
  font-size: var(--redo-body-small-text-size);
}

.summary-subtext {
  text-align: center;
  margin-top: 8px;
}

.exchange-boxes {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}

.exchange-box {
  width: 100%;
  border-radius: 10px;
  border: var(--redo-border-width) solid #a6a9ba;
  cursor: pointer;
  padding: 24px 20px;
}

.exchange-box-selected {
  width: 100%;
  border-radius: 10px;
  border: var(--redo-border-width) solid #181818;
  background-color: #f4f4f4;
  padding: 24px 20px;
}

.order-method-text {
  font-size: var(--redo-body-small-text-size);
  color: #a6a9ba;
  margin-top: 8px;
}

.bonus {
  display: flex;
  padding: 4px 12px;
  background-color: var(--redo-primary-color);
  color: var(--redo-text-color);
  border-radius: 50px;
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 132px;
}

.fee {
  display: flex;
  padding: 4px 12px;
  background-color: var(--redo-dark-gray);
  color: #fff;
  border-radius: 50px;
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 132px;
}

.green-return-adjustment {
  display: flex;
  padding: 4px 12px;
  background-color: var(--redo-primary-color);
  color: var(--redo-text-color);
  border-radius: 50px;
  font-size: var(--redo-body-small-text-size);
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 132px;
}

.adjustment-bonus {
  display: flex;
  padding: 4px 12px;
  background-color: var(--redo-primary-color);
  color: var(--redo-text-color);
  border-radius: 50px;
  font-size: var(--redo-body-small-text-size);
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 132px;
}

.adjustment-fee {
  display: flex;
  padding: 4px 12px;
  background-color: #555760;
  color: #fff;
  border-radius: 50px;
  font-size: var(--redo-body-small-text-size);
  height: 32px;
  justify-content: center;
  align-items: center;
  min-width: 132px;
}

.variant-title {
  color: #555760;
}

.card-item-title {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  width: 200px;
}

.edit {
  color: #424242;
  font-size: 12px;
  padding: 6px 10px;
  border: 1px solid #d6d6d6;
  filter: drop-shadow(0 1px 2px 0 rgba(16, 24, 40, 12.8));
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.edit:hover {
  color: fafafa;
}

.address-fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pickup-location-dropdown {
  width: 100%;
  > * {
    background-color: var(--redo-colors-background-bg-primary);
  }
}

.reminder-text-item {
  display: flex;
  justify-content: first baseline;
  gap: 5px;
}

@media screen and (max-width: 1100px) {
  .content-wrapper {
    padding: 0px 24px;
  }
  .card-image {
    width: 88px;
    min-width: 88px;
    aspect-ratio: var(--image-aspect-ratio-override, 1/1);
  }
  .left-content {
    min-width: auto;
    width: 100%;
  }
  .summary-cards {
    width: 100%;
  }
  .card-items {
    flex-direction: column;
    gap: var(--redo-spacing);
  }
  .title {
    font-size: var(--redo-body-small-text-size);
    color: #555760;
  }
  .price {
    font-size: var(--redo-body-small-text-size);
    color: #555760;
  }
  .card-item-title {
    width: 100%;
    gap: 0px;
  }
  .adjustment {
    width: fit-content;
    font-size: var(--redo-body-small-text-size);
    min-width: 0px;
  }
  .green-return-adjustment {
    width: fit-content;
    font-size: var(--redo-body-small-text-size);
    min-width: 0px;
  }
  .adjustment-fee {
    width: fit-content;
    font-size: var(--redo-body-small-text-size);
    min-width: 0px;
  }
  .adjustment-bonus {
    width: fit-content;
    font-size: var(--redo-body-small-text-size);
    min-width: 0px;
  }
  .card {
    height: 100%;
    padding: 12px 0px;
  }
  .variant-title {
    font-size: var(--redo-body-small-text-size);
    color: #555760;
  }
  .exchange-boxes {
    flex-direction: column;
    margin-bottom: 12px;
  }
  .input-row {
    padding: 0px;
    font-size: var(--redo-body-medium-text-size);
  }
  .covered {
    display: none;
  }
}
