.down-arrow {
  transform: rotate(-90deg);
}

.label {
  display: flex;
  justify-content: space-between;
}

.up-arrow {
  transform: rotate(90deg);
}
