.picker {
  padding: 8px;
  box-sizing: initial;
  background: var(--redo-colors-background-bg-primary);
  border-radius: 4px;
  border: solid var(--redo-border-color-dark) 0.8px;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.15),
    0 8px 16px rgba(0, 0, 0, 0.15);

  max-width: 210px;
}
.saturation {
  padding-bottom: 100%; /* This makes it a square through black magic */
  position: relative;
  border-radius: 4px;
  border: solid var(--redo-border-color-dark) 0.8px;
  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 0 4px rgba(0, 0, 0, 0.25);

  div {
    border-radius: 4px;
  }
}

.sliders {
  padding: 16px 0;
  flex: 1;
}

.hue {
  position: relative;
  height: 10px;
  border-radius: 5px;
  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 0 4px rgba(0, 0, 0, 0.25);

  div {
    border-radius: 5px;
  }
}

.alpha {
  position: relative;
  height: 10px;
  margin-top: 4px;
  border-radius: 5px;
  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.15),
    inset 0 0 4px rgba(0, 0, 0, 0.25);

  div {
    border-radius: 5px;
  }
}

.controls {
  display: flex;
}

.hex {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 8px;

  input {
    border-radius: 4px;
    border: solid var(--redo-border-color-dark) 0.8px;
    font-size: 10px;
    padding: 4px;
    width: 100%;
  }
}

.rgba-section {
  padding-left: 4px;
  display: flex;
  flex-direction: row;
  flex: 2;
}

.rgba {
  display: flex;
  flex-direction: column;
  font-size: 8px;

  input {
    border: solid var(--redo-border-color-dark);
    border-width: 0.8px 0 0.8px 0.8px;
    font-size: 10px;
    padding: 4px;
    width: 100%;
  }

  & + .rgba:last-of-type {
    input {
      border-radius: 0 4px 4px 0;
      border-width: 0.8px;
    }
  }
}

.rgba:first-of-type {
  input {
    border-radius: 4px 0 0 4px;
  }
}

.saturation-pointer {
  width: 12px;
  height: 12px;
  border: white 1px solid;
  border-radius: 50% !important;
  transform: translate(-6px, -6px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.hue-and-alpha-pointer {
  width: 12px;
  height: 12px;
  border: white 1px solid;
  border-radius: 50% !important;
  transform: translate(-6px, -1px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.badge {
  cursor: pointer;
}
