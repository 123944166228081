.container {
  display: flex;
  height: 100%;
  width: 100%;
  background: white;
  background-color: white;
  gap: 0;
}

.leftPanel {
  flex: 4;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  resize: horizontal;
  overflow: auto;
  background: white;
  padding: var(--redo-spacing-4xl) var(--redo-spacing-6xl);
  border-right: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  gap: var(--redo-spacing-4xl);
  scrollbar-width: thin;
  scrollbar-color: var(--redo-colors-border-border-primary) transparent;
}

.rightPanel {
  width: 320px;
  min-width: 320px;
  flex-shrink: 0;
  flex: 1;
  background: white;
  overflow-y: auto;
  padding-bottom: var(--redo-spacing-6xl);
}

.rightPanel::-webkit-scrollbar {
  width: 6px;
}

.rightPanel::-webkit-scrollbar-track {
  background: transparent;
}

.rightPanel::-webkit-scrollbar-thumb {
  background-color: var(--redo-colors-border-border-secondary);
  border-radius: 3px;
}

.rightPanel::-webkit-scrollbar-thumb:hover {
  background-color: var(--redo-colors-border-border-primary);
}
