.chevron {
  height: 16px;
  width: 16px;
  color: var(--redo-color-text-secondary);
}

.dropdown-icon {
  transform: rotate(0deg);
}

.dropdown-icon-open {
  transform: rotate(180deg);
}

.multiple-orders-title {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing-xs);
  flex-wrap: wrap;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

.multiple-orders-title:hover {
  color: var(--redo-color-text-primary);
}

.multiple-orders-dropdown-item {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  padding: var(--redo-spacing-xs);
}

.multiple-orders-dropdown-item:hover {
  background-color: var(--redo-colors-background-bg-secondary);
}

.multiple-orders-link {
  text-decoration: none;
  color: var(--redo-colors-text-text-primary-900);
}

.multiple-orders-link:hover {
  text-decoration: underline;
}

.external-link-icon {
  height: 12px;
  width: 12px;
  color: var(--redo-colors-text-text-primary-900);
}

.order-link {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
  text-decoration: none;
  font-weight: 600;
}

.order-link:hover {
  text-decoration: underline;
}
