.container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  text-decoration: underline;
}
