.call-card {
  height: 51px;
}

.call-message-button {
  background-color: var(--redo-colors-background-bg-primary);
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: var(--redo-corner-small-radius);
  padding: 0px 12px;
}

.vertical-divider {
  border-left: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  height: 80%;
}

.help-icon {
  height: 16px;
  width: 16px;
  color: var(--redo-colors-foreground-fg-disabled);
}

.help-icon-container {
  height: 16px;
}
