.info-section-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  flex: 1;
}

.info-section-title-row {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.strong {
  font-size: 14px;
  font-weight: 600;
}

.multi-line-info {
  display: flex;
  flex-direction: column;
}
