.customize-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  min-height: 100%;
  position: relative;

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--redo-colors-foreground-fg-error-primary);
    padding: 2px;

    div {
      display: flex;
    }
  }

  button {
    line-height: 1;

    div {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
    }
  }
}

.discount-value-type-dropdown > * {
  width: 100%;
  flex-grow: 1;
}

.label-small {
  font-size: var(--redo-body-small-text-size);
}
