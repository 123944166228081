.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.expandedImage {
  object-fit: contain;
  cursor: default;
  max-width: 95vw;
  max-height: 95vh;
  width: max-content;
  transform-origin: center;
}

.closeButton {
  position: absolute;
  top: 50px;
  right: 50px;
  height: 36px;
  width: 36px;
  color: white;
  cursor: pointer;
  z-index: 1000;
}

.closeButton:hover {
  color: gray;
}
