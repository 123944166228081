.variable {
  background-color: #ebe9fd;
  color: #5f45e2;
  border-radius: var(--redo-corner-xsmall-radius);
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.quill-editor {
  border: none !important;
  max-height: 500px !important;
  font-size: var(--redo-body-medium-text-size);
}
