.subtotal-collapse-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.collapse-icon {
  height: 16px;
  width: 16px;
  transition: transform 400ms;
  color: var(--redo-colors-text-text-tertiary-600);
}

.collapsed {
  transform: rotate(-90deg);
}

.left-right-shipping-chevron {
  color: var(--redo-colors-text-text-tertiary-600);
}

.card-padding {
  padding: 16px;
}
