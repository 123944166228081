.image {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: contain;
  cursor: pointer;
  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.file-type {
  display: flex;
  align-items: center;
  svg {
    border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  }
}

.file-type-name {
  text-overflow: ellipsis;
  color: var(--redo-colors-text-text-secondary-700);
}

.default-wrapper {
  background: #141414;
  width: 40px;
  display: flex;
  justify-content: center;
  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  svg {
    height: 40px;
    color: var(--redo-colors-text-text-primary-900);
  }
}

.download-button {
  margin-left: auto;
}

.file-info {
  display: flex;
  flex-direction: column;
  color: var(--redo-colors-text-text-primary-900);
  width: calc(70% - 16px); /* 16px is the padding of the file-container */
}

.file-info-width-restricted {
  display: flex;
  flex-direction: column;
  color: var(--redo-colors-text-text-primary-900);
  width: 90%;
}

.file-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: var(--redo-colors-background-bg-disabled);
  border: 1px solid var(--redo-colors-border-border-primary);
  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));
  align-items: center;
  height: 65px;
  padding: 8px;
  cursor: pointer;
  min-width: 0;
  &:hover {
    border: 1px solid var(--redo-colors-border-border-primary-hover);
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.link {
  max-width: 100%;
}
